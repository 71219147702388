import * as moment from 'moment';
import {CrmField} from './crm-field';
import {StatusEnum} from '../../enums/appointment-status';
import {Timezones} from '../../utils/timezones';



export class Appointment {
  appointmentId: number;
  appointmentGuid: string;
  startDate: string;
  endDate: string;
  callType: string;
  appointmentTypeId: number;
  categoryId: number;
  appointmentType: string;
  agentUserName: string;
  agent: string;
  description: string;
  status: StatusEnum;

  appointmentDate: string;
  startTime: string;
  endTime: string;

  customerId: number;
  userGuid: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  customerNotes: string;
  notes: string;


}

export class AppointmentType {
  id: number;
  name: string;
  callType?: string;
  customerInfo?: CrmField[];
  slotDuration?: number;
  locationIds?: number[];
  }

export class AppointmentFilterParameter {
  appointmentTypeId: number;
  callType: string;
  status: number;
  from: string;
  to: string;
  onlyCurrentAgentData: boolean;
  agents: string[];

  constructor() {
    this.appointmentTypeId = 0;
    this.callType = '';
    this.status = 0;
    this.onlyCurrentAgentData = true;
    this.agents = [];
  }
}

export class CreateAppointmentRequest {
  appointmentTypeId: number;
  appointmentGuid: string;
  categoryId: number;
  url: string;
  startDateTime: string;
  endDateTime: string;
  timezoneOffset: number;
  customerFormData: string;
  customerEmail: string;
  customerPhone: string;
  locationId: number;
  userGuid: string;
  siteSection: string;
  engagementGuid: string;
  description: string;
  notes: string;
  callType: string;
  status: number;
}

export class EditAppointmentRequest {
  customerInfo: string;
  email: string;
  phoneNumber: string;
  agent: string;
  description: string;
  notes: string;
  modifiedBy: string;
}

export enum SlotTypes {
  Unavailability,
  Availability
}

export class CreateAvailabilitySlotRequest {
  startDateTime: string;
  endDateTime: string;
  description: string;
  type: SlotTypes;
  appointmentGuid?: string;

  constructor() {
    this.type = SlotTypes.Unavailability;
  }
}

export class StoreLocation {
  id: number;
  name: string;
  phone: string;
  mobile: string;
  address: string;
  addressLine2: string;
  country: string;
  postcode: string;
}
