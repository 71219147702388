import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Appointment, CreateAvailabilitySlotRequest} from "../../../services/crm-service/appointment";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as moment from "moment/moment";
import {of, Subscription} from "rxjs";
import {AlertService, AlertType} from "../../../services/alert-service/alert.service";
import {AppointmentService} from "../../../services/appointment/appointment.service";
import {TranslatePipe} from "../../../filters/Translate.pipe";
import {TimeUtil} from "../../../utils/time-util";
import {catchError, first} from "rxjs/operators";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-availability-block',
  templateUrl: './availability-block.component.html',
  styleUrl: './availability-block.component.scss'
})
export class AvailabilityBlockComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public resetContents: boolean;
  @Input() public timezoneAbrv: string;
  @Input() public appointment: Appointment;

  @Output() onUpdate: EventEmitter<void> = new EventEmitter<void>();

  protected availabilityFormGroup: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private appointmentService: AppointmentService,
    private alertService: AlertService,
    private translate: TranslatePipe,
    private fb: FormBuilder,) {

  }

  ngOnInit(): void {

    this.availabilityFormGroup = this.fb.group({
      startDate: [moment().add(1, 'days').format('YYYY-MM-DD'), Validators.required],
      endDate: [moment().add(1, 'days').format('YYYY-MM-DD'), Validators.required],
      startTime: [moment().startOf('day').format('HH:mm'), Validators.required],
      endTime: [moment().endOf('day').format('HH:mm'), Validators.required],
      description: new FormControl('')
    });

    this.subscriptions.push(
      this.availabilityFormGroup.get('startDate').valueChanges.subscribe(date => {
        const endDate = this.availabilityFormGroup.get('endDate').value;
        // should be OK since the format YYYY-MM-DD is lexicographically sortable
        if (date > endDate) {
          this.availabilityFormGroup.get('endDate').setValue(date);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetContents?.currentValue) {
      this.resetContents = false;
      this.reset();
    }

    if (changes.appointment?.currentValue) {
      this.populateUnAvailableAptFields(this.appointment);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  populateUnAvailableAptFields(row: Appointment) {
    this.availabilityFormGroup.get('startDate').setValue(moment(this.appointment.startDate).format('YYYY-MM-DD'));
    this.availabilityFormGroup.get('startTime').setValue(moment(this.appointment.startDate).format('HH:mm'));
    this.availabilityFormGroup.get('endDate').setValue(moment(this.appointment.endDate).format('YYYY-MM-DD'));
    this.availabilityFormGroup.get('endTime').setValue(moment(this.appointment.endDate).format('HH:mm'));
    this.availabilityFormGroup.get('description').setValue(this.appointment.description);
  }

  deleteAvailabilityBlock() {
    this.appointmentService.deleteAvailabilityBlock(this.appointment.appointmentGuid).pipe(first()).subscribe(data => {
      if (data) {
        this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_UNAVAILABLEAPT_DELETED', 'Availability Block Deleted.'), AlertType.Success);
        this.onUpdate.emit();
      } else {
        this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_AVAILABILITY_BLOCK_DELETED', 'Availability Block Not Deleted.'), AlertType.Danger);
      }
    });
  }

  saveAvailabilityBlock() {
    const startDateTime = TimeUtil.CombineDateAndTime(this.availabilityFormGroup.get('startDate').value, this.availabilityFormGroup.get('startTime').value);
    const endDateTime = TimeUtil.CombineDateAndTime(this.availabilityFormGroup.get('endDate').value, this.availabilityFormGroup.get('endTime').value);
    const description = this.availabilityFormGroup.get('description').value;

    const request = new CreateAvailabilitySlotRequest();
    request.startDateTime = startDateTime;
    request.endDateTime = endDateTime;
    request.description = description;

    const validation = this.appointmentService.validAppointmentDates(request);
    if (!validation.valid) {
      this.alertService.addAlert(validation.errorMessage, AlertType.Danger);
      return;
    }

    if (this.appointment?.appointmentGuid?.length > 0) {
      request.appointmentGuid = this.appointment.appointmentGuid;
      this.appointmentService.updateAvailabilityBlock(this.appointment.appointmentGuid, request)
        .pipe(first(), catchError(err => {
          return of(null);
        })).subscribe(success => {
        if (success) {
          this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_AVAILABILITY_BLOCK_UPDATED', 'Availability Block Updated.'), AlertType.Success);
          this.onUpdate.emit();
        } else {
          this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_AVAILABILITY_BLOCK_NOT_UPDATED', 'Availability Block Not Updated.'), AlertType.Danger);
        }
      });
    } else {
      this.appointmentService.createAvailabilityBlock(request)
        .pipe(first(), catchError(err => {
          return of(null);
        })).subscribe(success => {
        if (success) {
          this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_UNAVAILABLEAPT_INSERTED', 'Availability Block Saved.'), AlertType.Success);
          this.onUpdate.emit();
        } else {
          this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_UNAVAILABLEAPT_NOT_INSERTED', 'Availability Block Not Saved.'), AlertType.Danger);
        }
      });
    }
  }

  private reset() {
    this.availabilityFormGroup?.reset({
      startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      startTime: moment().startOf('day').format('HH:mm'),
      endTime: moment().endOf('day').format('HH:mm'),
      description: ''
    });
  }

  protected readonly faSave = faSave;
  protected readonly faTrash = faTrash;
}
