import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, combineLatest, of, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {CrmService} from '../../services/crm-service/crm.service';
import {AppointmentType, CreateAppointmentRequest, StoreLocation} from '../../services/crm-service/appointment';
import {AuthService} from '../../services/auth-service/auth.service';
import {SessionTypes} from '../../services/crm-service/sessionTypes';
import {CrmField} from '../../services/crm-service/crm-field';
import {SettingsService} from '../../services/settings-service/settings.service';
import {AppointmentService} from '../../services/appointment/appointment.service';
import {StatusEnum} from '../../enums/appointment-status';
import {Timeslot} from '../../services/appointment/appointment.backend.service';
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {StringUtils} from "../../utils/string-utils";
import {LoggingService} from "../../services/logging.service";
import {DEFAULT_MS_TIMEZONE, MicrosoftTimeZone, Timezones} from "../../utils/timezones";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public submittingForm: boolean;
  @Input() public resetContents: boolean;
  @Input() public isEditing: boolean = true;

  @Output() public submitAppointment = new EventEmitter<CreateAppointmentRequest>();
  @Output() public cancelCreateAppointment = new EventEmitter<void>();

  public readonly timeZone$ = new BehaviorSubject<MicrosoftTimeZone>(DEFAULT_MS_TIMEZONE);
  private readonly slots$ = new BehaviorSubject<Timeslot[]>([]);

  public appointmentFormGroup: FormGroup;
  public dynamicFormGroup: FormGroup;
  public siteSections: string[] = [];
  public engagementTypes: SessionTypes[] = [];
  public appointmentTypes: AppointmentType[] = [];
  public storeLocations: StoreLocation[] = [];
  public formFields: CrmField[] = [];

  public readonly selectedAppointmentType$ = new BehaviorSubject<AppointmentType | null>(null);
  public readonly appointmentLocations$ = new BehaviorSubject<StoreLocation[]>([]);
  public readonly selectedLocationId$ = new BehaviorSubject<number>(-1);
  public readonly startDate$ = new BehaviorSubject<Date>(new Date());
  public readonly selectedTimeslot$ = new BehaviorSubject<Timeslot | null>(null);

  public readonly availabilitySlots$ = combineLatest([
    this.timeZone$,
    this.startDate$,
    this.slots$
  ]).pipe(
    map(([timezone, startDate, slots]) => AppointmentService.ParseSlotsForDate(moment(startDate).format('YYYY-MM-DD'), slots, timezone))
  );

  protected readonly faSave = faSave;
  protected readonly CrmField = CrmField;
  protected readonly StringUtils = StringUtils;
  protected readonly Timezones = Timezones;

  private subscriptions = new Subscription();
  protected appointmentTypeId = -1;
  protected slotDurationMins = 60;
  protected callType = '';
  protected customerInfo: CrmField[] = [];

  constructor(
    private readonly appointmentService: AppointmentService,
    private readonly crmService: CrmService,
    private readonly authService: AuthService,
    private readonly settingsService: SettingsService,
    private readonly loggingService: LoggingService,
    private readonly fb: FormBuilder,
  ) {
    this.dynamicFormGroup = this.fb.group({});
    this.appointmentFormGroup = this.createAppointmentFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetContents?.currentValue) {
      this.resetContents = false;
      this.reset();
    }
  }

  ngOnInit(): void {
    this.initialiseSubscriptions();
    this.loadInitialData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createAppointment(): void {
    const timeslot = this.selectedTimeslot$.getValue();
    this.submitAppointment.emit({
      userGuid: '',
      appointmentTypeId: this.appointmentTypeId,
      categoryId: -1,
      locationId: this.appointmentFormGroup.get('locationId')?.value || -1,
      customerEmail: this.getFieldByType('Email')?.value || '',
      customerPhone: this.getFieldByType('Phone')?.value || '',
      customerFormData: this.dynamicFormGroup.getRawValue(),
      endDateTime: timeslot?.end || '',
      startDateTime: timeslot?.start || '',
      timezoneOffset: 0,
      siteSection: 'MainSite',
      url: window?.location?.hostname || '',
      engagementGuid: '',
      appointmentGuid: '',
      description: '',
      notes: this.appointmentFormGroup.get('notes')?.value || '',
      callType: this.appointmentFormGroup.get('callType')?.value || '',
      status: StatusEnum.OPEN,
    });
  }

  isVisible(name: string): boolean {
    return name && !['Vee24Guid', 'Appointed Store', 'Custom Task'].includes(name);
  }

  reset(): void {
    this.slots$.next([]);
    this.appointmentLocations$.next([]);
    this.selectedLocationId$.next(-1);
    this.selectedTimeslot$.next(null);
    this.appointmentTypeId = -1;
    this.slotDurationMins = 60;
    this.callType = '';
    this.customerInfo = [];
    this.formFields = [];
    this.dynamicFormGroup.reset();
    this.resetAppointmentForm();
  }

  updateFormDefaults(): void {
    this.appointmentFormGroup.patchValue({callType: this.callType});
    this.dynamicFormGroup = this.fb.group({});
    this.formFields = this.customerInfo;
    this.formFields.forEach((field) => {
      field.value = '';
      const control = this.fb.control('', field.required === '1' ? Validators.required : []);
      this.dynamicFormGroup.addControl(field.name, control);
      this.addFieldTypeSpecificSettings(field);
      this.addTranslation(field);
    });
  }

  private resetAppointmentForm(): void {
    this.appointmentFormGroup.reset({
      appointmentType: null,
      callType: '',
      siteSection: '',
      startDate: moment().format('YYYY-MM-DD'),
      startTime: null,
      endTime: null,
      timeslot: null,
      locationId: -1,
      notes: ''
    });
  }

  initialiseSubscriptions(): void {
    this.subscriptions.add(
      this.selectedAppointmentType$.subscribe((appointmentType) => {
        if (appointmentType) {
          this.reset();
          this.appointmentTypeId = appointmentType?.id || -1;
          this.slotDurationMins = appointmentType?.slotDuration || 60;
          this.callType = appointmentType?.callType || '';
          this.customerInfo = appointmentType?.customerInfo || [];

          const parsedLocations = appointmentType?.locationIds?.map(id => this.storeLocations.find(location => location.id === id));
          this.appointmentLocations$.next(parsedLocations);
        }
      })
    );

    this.subscriptions.add(
      this.selectedTimeslot$.pipe(distinctUntilChanged()).subscribe((slot) => {
        if (slot) {
          this.appointmentFormGroup.patchValue({
            startTime: slot.start,
            endTime: slot.end,
          });
          this.updateFormDefaults();
        }
      })
    );

    this.subscriptions.add(
      combineLatest([this.selectedAppointmentType$, this.startDate$, this.selectedLocationId$])
        .pipe(
          filter(([appointmentType, date]) => !!appointmentType && !!date),
          debounceTime(1000),
          tap(([appointmentType, date, locationId]) => this.getAvailabilitySlotsFor(appointmentType?.id || -1, moment(date).format('YYYY-MM-DD'), locationId || -1))
        )
        .subscribe()
    );

    this.subscribeToFormChanges();
  }

  loadInitialData(): void {
    this.authService.currentAgent.pipe(
      first(),
      catchError(() => {
        this.loggingService.error('Error fetching current agent');
        return of(null);
      })
    ).subscribe(agent => {
      if (agent) {
        this.timeZone$.next(agent.timezone as MicrosoftTimeZone);
      }
    });

    this.crmService.loadAll().pipe(
      first(),
      switchMap(() =>
        combineLatest([
          this.crmService.siteSections.pipe(first()),
          this.crmService.sessionTypes.pipe(first()),
          this.appointmentService.loadAppointmentTypes().pipe(first()),
          this.appointmentService.getLocations().pipe(first()),
        ])
      ),
      tap(([siteSections, sessionTypes, appointmentTypes, locations]) => {
        this.siteSections = siteSections;
        this.engagementTypes = sessionTypes.filter((s) => s.Schedulable);
        this.appointmentTypes = appointmentTypes;
        this.storeLocations = locations;
      }),
      catchError((error) => {
        this.loggingService.error('Error loading initial data', error);
        return of([]);
      })
    ).subscribe();
  }

  getAvailabilitySlotsFor(appointmentTypeId: number, date: string, locationId: number = -1): void {
    if (!appointmentTypeId || !date) return;

    this.subscriptions.add(
      this.appointmentService
        .getAvailabilitySlots(appointmentTypeId, date, 0, locationId)
        .pipe(
          tap((slots) => this.slots$.next(slots)),
          catchError((error) => {
            this.loggingService.error('Error fetching availability slots', error);
            return of([]);
          })
        )
        .subscribe()
    );
  }

  saveCrmData() {
    // nothing
  }

  private addFieldTypeSpecificSettings(field: CrmField): void {
    switch (field.name?.toLowerCase()) {
      case 'email':
        field.type = CrmField.TYPE_EMAIL;
        break;
      case 'phone number':
        field.type = CrmField.TYPE_PHONE;
        break;
    }

    if ((field as unknown as any)?.options) {
      field.choices = (field as unknown as any).options;
    }
  }

  private addTranslation(field: CrmField): void {
    const resourceKey = `CRM_FIELD:${field.name}`;
    const defaultTitle = field.name.replace(/([A-Z]+)/g, ' $1').trim();
    field.translatedTitle = this.settingsService.getResourceOrDefault(resourceKey, defaultTitle);
  }

  private getFieldByType(type: string): CrmField | undefined {
    return this.customerInfo?.find((field) => field.type?.toLowerCase() === type.toLowerCase());
  }

  private createAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      appointmentType: [null],
      locationId: [null],
      callType: ['', Validators.required],
      siteSection: [''],
      startDate: [moment().format('YYYY-MM-DD'), Validators.required],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
      timeslot: [null, Validators.required],
      notes: [''],
    });
  }

  private subscribeToFormChanges(): void {
    const formControls = this.appointmentFormGroup.controls;

    this.subscriptions.add(
      formControls.startTime.valueChanges.pipe(
        tap((startTime) => formControls.endTime.setValue(
          moment(startTime, 'HH:mm').add(this.slotDurationMins, 'minutes').format('HH:mm')
        ))
      ).subscribe()
    );

    this.subscriptions.add(
      formControls.startDate.valueChanges.pipe(
        tap((startDate) => this.startDate$.next(startDate))
      ).subscribe()
    );

    this.subscriptions.add(
      formControls.appointmentType.valueChanges.pipe(
        tap((type) => this.selectedAppointmentType$.next(type))
      ).subscribe()
    );

    this.subscriptions.add(
      formControls.timeslot.valueChanges.pipe(
        tap((slot) => this.selectedTimeslot$.next(slot))
      ).subscribe()
    );

    this.subscriptions.add(
      formControls.locationId.valueChanges.pipe(
        tap((id) => this.selectedLocationId$.next(id))
      ).subscribe()
    );
  }
}
