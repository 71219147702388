import {LoggingService} from './logging.service';
import Guid from '../classes/Guid';
import {BehaviorSubject, forkJoin, from, Observable, of, ReplaySubject, Subject, Subscription, timer} from 'rxjs';
import {
  Agent as HubAgent,
  ChatMessageEvent,
  ConnectionInfo,
  EngagementOptions,
  EngagementServiceEvent,
  EngagementServiceEvents,
  HubTextMessage,
  IEngagementHubService,
  JoinRoomState,
  PresenterRoomState,
  RejoinRoomState,
  Room,
  StartRoomState,
  SupervisorRoomState,
  TransferRoomState,
  VisitorCRMData
} from './engagementhub.service';
import {CallType} from '../enums/call-type.enum';
import {SiteVisitor} from './visitor-service/SiteVisitor';
import {FileTransferService, TransferId} from './file-transfer-service/file-transfer.service';
import {IFileUpload} from './file-transfer-service/file-upload-interface';
import {TransferState} from './file-transfer-service/file-upload';
import {TranslationService} from './translation-service/translation.service';
import {catchError, distinctUntilChanged, filter, map, skip, take, withLatestFrom} from 'rxjs/operators';
import {Translation} from './translation-service/translation';
import {Agent} from '../classes/agent';
import {IAuthService} from './auth-service/auth.service.interface';
import {Features, IFeatureService} from './feature-service/feature.service';
import {AlertService, AlertType} from './alert-service/alert.service';
import {environment} from '../../environments/environment';
import {CrmData, CrmStructure} from './crm-service/crm-category';
import {CrmService} from './crm-service/crm.service';
import {DeliveryStatus, TextMessage, TextMessages, TextMessageTypes} from '../classes/TextMessage';
import {FeedType} from '../enums/multipeer/feed-type.enum';
import {InviteService} from './invite-service/invite.service';
import {CustomerInvite} from '../components/engagement-join-customers/CustomerInvite';
import {CustomerInviteTypes} from '../components/engagement-join-customers/CustomerInviteTypes';
import {SettingsService} from './settings-service/settings.service';
import {LicenceType} from '../enums/licence-type.enum';
import {CameraDirection} from '../classes/camera-direction';
import {Size} from '../classes/Size';
import {EngagementMode} from '../enums/engagement-mode';
import {PostEngagementStatus} from '../classes/post-engagement.status';
import {CustomerChatHistory} from './crm-service/customer-chat-history';
import {EngagementHubVisitor, EngagementVisitor} from './engagement-visitor';

export enum EngagementState {
  Idle,
  Engaged,
  Post,
  Join,
  Transfer,
  Ended,
}

export enum CallInviteState {
  InviteSelecting,
  InviteWaiting,
  InviteRejected,
  InviteAccepted,
}

export enum EngagementStartState {
  Normal,
  Transfer,
  Join,
  Supervisor,
  Presenter,
  Rejoin,
}

export interface EngagementStateIdle {
  type: EngagementState.Idle;
  startState: EngagementStartState;
}

export interface EngagementStateEngaged {
  type: EngagementState.Engaged;
}

export interface EngagementStatePost {
  type: EngagementState.Post;
}

export interface EngagementStateEnded {
  type: EngagementState.Ended;
  // add a subtype: EndedBy type?
}

export interface JoinStateSelecting {
  type: EngagementState.Join;
  inviteState: CallInviteState.InviteSelecting;
}

export interface JoinStateWaiting {
  type: EngagementState.Join;
  inviteState: CallInviteState.InviteWaiting;
  operatorUsername: string;
  operatorFullname: string;
}

export interface JoinStateRejected {
  type: EngagementState.Join;
  inviteState: CallInviteState.InviteRejected;
  operatorUsername: string;
  operatorFullname: string;
  reason: string;
}

export interface JoinStateAccepted {
  type: EngagementState.Join;
  inviteState: CallInviteState.InviteAccepted;
}

export interface TransferStateSelecting {
  type: EngagementState.Transfer;
  inviteState: CallInviteState.InviteSelecting;
}

export interface TransferStateWaiting {
  type: EngagementState.Transfer;
  inviteState: CallInviteState.InviteWaiting;
  operatorUsername: string;
  operatorFullname: string;
}

export interface TransferStateRejected {
  type: EngagementState.Transfer;
  inviteState: CallInviteState.InviteRejected;
  operatorUsername: string;
  operatorFullname: string;
  reason: string;
}

export interface TransferStateAccepted {
  type: EngagementState.Transfer;
  inviteState: CallInviteState.InviteAccepted;
}

export type InviteStateType = JoinStateType | TransferStateType;
export type JoinStateType = JoinStateSelecting | JoinStateWaiting | JoinStateRejected | JoinStateAccepted;
export type TransferStateType =
  TransferStateSelecting
  | TransferStateWaiting
  | TransferStateRejected
  | TransferStateAccepted;
export type EngagementStateType =
  EngagementStateIdle
  | EngagementStateEngaged
  | EngagementStatePost
  | InviteStateType
  | EngagementStateEnded;

export enum WebrtcMessageType {
  AddStream,
  RemoveStream,
  SignallingMessage,
}

class WebrtcMessageAddStream {
  type: WebrtcMessageType.AddStream;
  streamName: string;
  peerType: string;
}

class WebrtcMessageRemoveStream {
  type: WebrtcMessageType.RemoveStream;
  streamName: string;
}

class WebrtcMessageSignalling {
  type: WebrtcMessageType.SignallingMessage;
  message: any;
}

export type WebrtcMessage = WebrtcMessageAddStream | WebrtcMessageRemoveStream | WebrtcMessageSignalling;

export enum PanelSize {
  Small = 0,
  Normal = 1,
  Big = 2,
  HD = 3
}

export interface CustomerSizes {
  browserWidth: number;
  browserHeight: number;
  size: PanelSize;
  panelDimensions: string;
  videoSize?: PanelSize;
}

export enum CommunicationMode {
  OFF = 0,
  TWOWAYAUDIO_NOVIDEO = 1,
  TWOWAYAUDIO_VIDEO = 2
}

export interface EngagementConfig {
  id: string;
  visitor: SiteVisitor;
  username: string;
  sitename: string;
  nickname: string;
  photo: string;
  widephoto: string;
  textChat: boolean;
  webrtcIceServer: string;
  startState: EngagementStartState;
  callType: CallType;
  feedLocation: string;
  isPresentationDevice: boolean;
  licenceType: LicenceType;
}

export interface EngagementAgent {
  username: string;
  photo: string;
  nickname: string;
  lastUpdatedMS: number;
}

export enum EngagementEventTypes {
  VeeStudio = "VeeCall",
  VeeChat = "Chat",
}

export type EngagementEventType = EngagementEventTypes.VeeStudio | EngagementEventTypes.VeeChat;


export class Engagement {
  private static readonly ROOM_AGENT_LOCK_TIMEOUT_MS = 15 * 1000;

  private static readonly STATE_ENGAGED: EngagementStateEngaged = {type: EngagementState.Engaged};
  private static readonly STATE_POST: EngagementStatePost = {type: EngagementState.Post};
  private static readonly STATE_ENDED: EngagementStateEnded = {type: EngagementState.Ended};

  private static readonly STATE_JOIN_SELECTING: JoinStateSelecting = {
    type: EngagementState.Join,
    inviteState: CallInviteState.InviteSelecting
  };
  private static readonly STATE_JOIN_ACCEPTED: JoinStateAccepted = {
    type: EngagementState.Join,
    inviteState: CallInviteState.InviteAccepted
  };

  private static readonly STATE_TRANSFER_SELECTING: TransferStateSelecting = {
    type: EngagementState.Transfer,
    inviteState: CallInviteState.InviteSelecting
  };
  private static readonly STATE_TRANSFER_ACCEPTED: TransferStateAccepted = {
    type: EngagementState.Transfer,
    inviteState: CallInviteState.InviteAccepted
  };


  private _cameraDirection: CameraDirection;

  public isConnected: Observable<boolean>;
  private startMessage: TextMessage;

  public get cameraDirection(): CameraDirection {
    return this._cameraDirection;
  }

  private _messages: TextMessages = new TextMessages(this.logging);
  public get messages(): TextMessages {
    return this._messages;
  }

  public newMessage = new Subject<{ privateMessage: boolean, message: TextMessage }>();

  public readonly visitorTypingMessage = new BehaviorSubject<TextMessage | null>(null);

  private _privateMessages: TextMessage[] = [];
  public readonly privateMessages: BehaviorSubject<TextMessage[]> = new BehaviorSubject([]);

  private subscriptions: Subscription[] = [];

  public readonly visitorMissing = new BehaviorSubject<boolean>(false);
  public readonly visitorEnded = new Subject<boolean>();
  public readonly webrtcMessages = new Subject<WebrtcMessage>();


  private agentLockTimerSub: Subscription = undefined;

  public get roomLocked(): boolean {
    return this.agentLockTimerSub !== undefined;
  }

  private _engagementId: Guid;
  public get engagementId(): Guid {
    return this._engagementId;
  }

  private _username: string;
  public get username(): string {
    return this._username;
  }

  public get canHaveAV() {
    return this.callType !== CallType.TextDowngrade && this.callType !== CallType.Text;
  }


  private webrtcIceServer: string;
  /** @deprecated */
  public wrServer: string;

  private readonly callType: CallType;

  private options: EngagementOptions;

  public readonly textVisible = new BehaviorSubject<boolean>(false);
  public readonly videoVisible = new BehaviorSubject<boolean>(false);

  public readonly cameraEnabled = new BehaviorSubject<boolean>(false);
  public readonly micEnabled = new BehaviorSubject<boolean>(false);
  public readonly customerCogOn = new BehaviorSubject<boolean>(false);

  public readonly viewingOperatorStream = new BehaviorSubject<boolean>(false);
  public readonly hearingOperatorStream = new BehaviorSubject<boolean>(false);

  public readonly micGain = new BehaviorSubject<number>(0.0);
  public readonly speakerVolume = new BehaviorSubject<number>(0.0);

  public readonly callPaused = new BehaviorSubject<boolean>(false);

  public readonly visitorIsSharing = new BehaviorSubject<boolean>(false);

  public readonly videoPaused = new BehaviorSubject<boolean>(false);

  public readonly chatOnly = new BehaviorSubject<boolean>(true);

  public readonly communicationMode = new BehaviorSubject<CommunicationMode>(CommunicationMode.OFF);
  public readonly engagementMode = new BehaviorSubject<EngagementMode>(EngagementMode.UNKNOWN);

  public readonly panelFullSize = new BehaviorSubject<boolean>(true);

  public readonly currentPage = new BehaviorSubject<string>('');

  public readonly cameraAccessGranted = new BehaviorSubject<boolean>(false);
  public readonly cameraAlreadyInUse = new BehaviorSubject<boolean>(false);
  public readonly micAccessGranted = new BehaviorSubject<boolean>(false);
  public readonly micAlreadyInUse = new BehaviorSubject<boolean>(false);
  public readonly micActivityLevel = new BehaviorSubject<number>(0.0);

  public readonly backgrounded = new BehaviorSubject(false);

  // Count of messages that have been received without an agent replying
  public readonly unreadMessages = new BehaviorSubject<number>(0);
  public readonly unreadPrivateMessages = new BehaviorSubject<number>(0);
  public readonly lastContactTime = new BehaviorSubject<Date>(new Date());

  public readonly primaryAgent = new BehaviorSubject('');
  public readonly presentingAgent = new BehaviorSubject('');

  public readonly primaryCustomer = new BehaviorSubject('');
  public onPrimaryCustomerChanged$ = this.primaryCustomer.pipe(filter(customer => customer?.length > 0), distinctUntilChanged());
  public readonly primaryVisitor$: BehaviorSubject<EngagementVisitor> = new BehaviorSubject<EngagementVisitor>(null);
  public readonly visitorHeartbeat = this.primaryVisitor$.pipe(map(visitor => visitor?.isOnline), distinctUntilChanged());

  private currentRoomTime: string;

  // todo: do these need to be behaviour subjects?
  public readonly isPrimary = this.primaryAgent.pipe(distinctUntilChanged(), map(primary => this.username === primary));
  public readonly isPresenter = this.presentingAgent.pipe(distinctUntilChanged(), map(presenter => this.username === presenter));

  public readonly allowBrowsing = new BehaviorSubject<boolean>(true);
  public readonly engagementUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private readonly _panelPositionAndSize = new BehaviorSubject<CustomerSizes>({
    browserWidth: 0,
    browserHeight: 0,
    size: PanelSize.Normal,
    panelDimensions: '0,0,0,0',
    videoSize: PanelSize.Normal,
  });
  public readonly panelPositionAndSize =
    this._panelPositionAndSize.pipe(
      distinctUntilChanged((a, b) => {
        return (a.browserWidth === b.browserWidth)
          && (a.browserHeight === b.browserHeight)
          && (a.size === b.size)
          && (a.videoSize === b.videoSize)
          && (a.panelDimensions === b.panelDimensions);
      }));

  public readonly panelVisible = new BehaviorSubject<boolean>(false);
  public readonly panelSize = new BehaviorSubject<PanelSize>(PanelSize.Normal);

  public readonly currentState: BehaviorSubject<EngagementStateType>;

  public readonly engagementEnded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public chatName: BehaviorSubject<String> = new BehaviorSubject<String>('');

  private fileTransfers: Map<TransferId, IFileUpload> = new Map([]);
  private fileTransferSubs: Map<TransferId, Subscription> = new Map([]);
  public fileTransfers$: BehaviorSubject<Map<TransferId, IFileUpload>> = new BehaviorSubject(this.fileTransfers);

  public privateChatEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public supPublicChatEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public agentAssistBotAutosendMessage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public agentAssistBotGetSuggestions: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public agentText: string = '';

  public isPrimaryAgent(): boolean {
    return this.username === this.primaryAgent.value;
  }

  public isPresentingAgent(): boolean {
    return this.username === this.presentingAgent.value;
  }

  private _roomAgents: Map<string, EngagementAgent> = new Map([]);
  public roomAgents: ReadonlyMap<string, EngagementAgent> = this._roomAgents;

  //TODO: we should look at removing SiteVisitor and just having the EngagementVisitor
  private _visitor: SiteVisitor;
  public get visitor(): SiteVisitor {
    return this._visitor;
  }


  private _roomVisitors: Map<string, EngagementVisitor> = new Map([]);

  public get roomVisitors() {
    return this._roomVisitors;
  }

  public visitorJoined$: ReplaySubject<EngagementVisitor> = new ReplaySubject(1);
  public visitorLeft$: ReplaySubject<EngagementVisitor> = new ReplaySubject(1);

  public readonly currentAgent: Agent;

  public readonly translationOn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public requestingHelp$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public currentFeedType$: BehaviorSubject<FeedType> = new BehaviorSubject<FeedType>(FeedType.Agent);
  public currentFeedId$: BehaviorSubject<number> = new BehaviorSubject(0);


  public authenticatedIceUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);

  public roomFull$: BehaviorSubject<boolean> = new BehaviorSubject(false);


  public domSyncOn$ = new BehaviorSubject<boolean>(false);
  public blockedOn$ = new BehaviorSubject<boolean>(false);
  public domSyncAccepted$ = new BehaviorSubject<boolean>(false);
  public domSyncCommand$ = new Subject<string>();
  public sendDomSyncCommand$ = new Subject<string>();
  public domSyncMessage$ = new Subject<string>();
  public isDomSync$ = new BehaviorSubject<boolean>(false);

  public readonly isAppView = new Subject<boolean>();
  public readonly isBrowserVisible = new BehaviorSubject<boolean>(false);
  public readonly isInBackground = new BehaviorSubject<boolean>(false);
  public readonly browserSize: BehaviorSubject<Size> = new BehaviorSubject<Size>({width: 0, height: 0});
  public readonly sharingSize: BehaviorSubject<Size> = new BehaviorSubject<Size>({width: 0, height: 0});
  public readonly screenSize: BehaviorSubject<Size> = new BehaviorSubject<Size>({width: 0, height: 0});

  public postEngagementStatus: PostEngagementStatus = new PostEngagementStatus();

  public readonly chatHistory: CustomerChatHistory;

  private hasCustomerVerification: boolean = false;

  constructor(
    private fileTransferService: FileTransferService,
    private engagementHub: IEngagementHubService,
    configuration: EngagementConfig,
    private authService: IAuthService,
    private logging: LoggingService,
    private translationService: TranslationService,
    private featureService: IFeatureService,
    private alertService: AlertService,
    private crmService: CrmService,
    private inviteService: InviteService,
    private settingService: SettingsService
  ) {
    const startState: EngagementStateType = {
      type: EngagementState.Idle,
      startState: configuration.startState
    };
    this.currentState = new BehaviorSubject<EngagementStateType>(startState);

    this.logging.debug('Engagement ctor');

    this.isConnected = engagementHub.isConnected.asObservable();

    this.isConnected.pipe(distinctUntilChanged(), skip(2)).subscribe(connected => {
      if (connected) {
        this.logging.info('Connected to the engagement hub');
        this.alertService.addResourceAlert('ENGAGEMENT_HUB_RECONNECT', 'You have reconnected to the engagement.', AlertType.Success);
      } else {
        this.logging.warn('Disconnected from the engagement hub');
        this.alertService.addResourceAlert('ENGAGEMENT_HUB_DISCONNECT', 'You have been disconnected from the engagement. Reconnecting...');
      }
    });

    this._engagementId = new Guid(configuration.id);
    this._username = configuration.username;

    if (configuration.webrtcIceServer && configuration.webrtcIceServer.length > 0) {
      this.webrtcIceServer = configuration.webrtcIceServer;
      this.authenticatedIceUrl$.next(configuration.webrtcIceServer);
    }

    this.subscriptions.push(this.engagementHub.events.subscribe(this.onEngagementEvent.bind(this)));

    this._visitor = configuration.visitor;
    this.callType = configuration.callType;

    this.currentAgent = this.authService.currentAgent.value;

    this.wrServer = 'deprecated';

    this.options = {
      id: this._engagementId,
      sessionId: new Guid(configuration.visitor.sessionGuid), // todo: sessionId was only only there for logPage, check not required
      username: configuration.username,
      sitename: configuration.sitename,
      connectionType: (startState.startState === EngagementStartState.Supervisor) ? 8 : 1,
      listType: configuration.licenceType,
      nickname: configuration.nickname,
      photo: configuration.photo,
      widephoto: configuration.widephoto,
      feedLocation: configuration.feedLocation,
      isPresentationDevice: configuration.isPresentationDevice,
    };

    this.hasCustomerVerification = this.featureService.has(Features.VERIFY_CUSTOMER);

    this.domSyncCommand$
      .pipe(
        withLatestFrom(this.isPrimary, this.domSyncAccepted$),
        map(([command, primary, accepted]) => {

          if (primary && accepted) {
            return command;
          }
        }));


    this.subscriptions.push(
      this.sendDomSyncCommand$.pipe(withLatestFrom(this.isPrimary, this.domSyncAccepted$))
        .subscribe(([command, primary, accepted]) => {

          if (primary && accepted) {
            this.engagementHub.sendDomSyncCommand(command)
              .catch(err => this.logging.warn(`Unable to send domsync command in engagement ${this.engagementId}`, err));
          }

        }));


    this.subscriptions.push(
      this.domSyncMessage$
        .pipe(withLatestFrom(this.isPrimary))
        .subscribe(([message, primary]) => {

          if (primary) {
            const customerMessage = message.trim();

            if (customerMessage === "accepted") {
              this.alertService.addAlert('Caller has accepted Co-View Session', AlertType.Success);
              this.domSyncAccepted$.next(true);
            } else if (customerMessage === "rejected") {
              this.alertService.addAlert('Caller does not wish to engage in a Co-View Session', AlertType.Danger);
              this.startDomSync(false);
              this.setDomSyncOn(false);
            } else if (customerMessage === "stop") {
              this.alertService.addAlert('Caller has stopped the Co-View Session', AlertType.Danger);
              this.startDomSync(false);
              this.setDomSyncOn(false);

            }
          }
        }));

    this.subscriptions.push(
      this.primaryVisitor$.pipe(
        filter(Boolean),
      ).subscribe(visitor => this.updatePrimaryVisitor(visitor))
    );

    this.chatOnly.next(configuration.textChat); // todo: this always seems to be false ...

    switch (startState.startState) {
      case EngagementStartState.Normal:
        this.primaryAgent.next(this.username);
        this.presentingAgent.next(this.username);

        const roomState: StartRoomState = {
          type: 'initial',
          SyncBrowser: false,
          SyncDom: false,
          CurrentPage: '', // todo: this
          BrowserMode: "browsing",
          Sharing: false,
          SharingMode: '',
          OnHold: false,
          ChatOnly: configuration.textChat,
          CallType: configuration.callType,
          EmulatingDevice: false,
          UpgradeInProgress: false,
          AuthenticatedIceUrl: configuration.webrtcIceServer,
          AgentAssistAutosendMessage: false,
          AgentAssistBotGetSuggestions: false,
          AgentAssistBotEnabledForRoom: false
        };

        this.engagementHub.initialise(this.options, roomState);
        break;
      case EngagementStartState.Transfer:
        this.primaryAgent.next(this.username);
        this.presentingAgent.next(this.username);

        this.engagementHub.initialise(this.options, new TransferRoomState());
        break;
      case EngagementStartState.Supervisor:
        this.primaryAgent.next('');
        this.engagementHub.initialise(this.options, new SupervisorRoomState());
        break;
      case EngagementStartState.Join:
        this.engagementHub.initialise(this.options, new JoinRoomState());
        break;
      case EngagementStartState.Presenter:
        this.engagementHub.initialise(this.options, new PresenterRoomState());
        break;
      case EngagementStartState.Rejoin:
        this.engagementHub.initialise(this.options, new RejoinRoomState());
        break;
    }

    this.startMessage = {
      id: -0.5,
      senderName: 'system',
      senderId: 'system',
      senderType: TextMessageTypes.SESSION_START,
      message: this.engagementTypeMessage(this._visitor.engagementType),
      timestamp: new Date(),
      originalMessage: '',
      currentEngagement: true,
      verified: null
    };
    this._messages.addMessage(this.startMessage);

    this.chatHistory = new CustomerChatHistory(this.crmService, this.settingService, new Guid(this._visitor.userGuid), this._engagementId, this.username);
    this.chatHistory.updateSessionHistory(this.visitor.sessionHistory.value);
    this.chatHistory.loadMore().then(items => {
      this.messages.addHistory(this.chatHistory.history);
      this.logging.debug(`Loaded ${items.length} messages.`);
    });
  }

  initialise(): Observable<boolean> {
    return new Observable(obs => {
      this.engagementHub.connect().subscribe(connected => {
        if (connected) {
          try {
            this.onConnected();
            obs.next(true);
          } catch (e) {
            this.logging.error(`Error in onConnected() method.`, e);
            obs.next(false);
          }
        } else {
          // todo: transition to engagement ended correctly
          obs.next(false);
        }
        obs.complete();
      }, err => {
        // todo: transition to engagement ended correctly
        obs.next(false);
        obs.complete();
      });
    });
  }

  private dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.authenticatedIceUrl$.complete();
    this.engagementHub.disconnect();
  }

  private onEngagementEvent(event: EngagementServiceEvent) {
    switch (event.type) {
      case EngagementServiceEvents.ChatMessage:
        this.onChatMessageEvent(event);
        break;
      case EngagementServiceEvents.PrivateChatMessage:
        this.onPrivateChatMessage(event.message);
        break;
      case EngagementServiceEvents.AgentEndedCall:
        this.agentEndsCall();
        break;
      case EngagementServiceEvents.SystemMessage:
        // Not used
        break;
      case EngagementServiceEvents.AgentLock:
        this.agentLocksCall(event.isLocked);
        break;
      case EngagementServiceEvents.VisitorLeft:
        this.visitorEndedEngagement(event.visitor);
        break;
      case EngagementServiceEvents.VisitorTyping:
        this.visitorTyping(event.message);
        break;
      case EngagementServiceEvents.RoomUpdate:
        this.updateRoom(event.room);
        break;
      case EngagementServiceEvents.VisitorUpdate:
        const visitor = this.createOrUpdateVisitor(event.visitor);
        if (visitor.isPrimary) {
          this.primaryVisitor$.next(visitor);
          this.primaryCustomer.next(visitor.userGuid);
        }
        break;
      case EngagementServiceEvents.StreamUpdate:
        break;
      case EngagementServiceEvents.WebrtcSignallingUpdate:
        this.webrtcSignalling(event.message);
        break;
      case EngagementServiceEvents.TransferAccepted:
        this.transferAccepted();
        break;
      case EngagementServiceEvents.TransferRejected:
        this.transferRejected(event.operatorUsername, event.reason);
        break;
      case EngagementServiceEvents.InviteAccepted:
        this.joinAccepted();
        break;
      case EngagementServiceEvents.InviteRejected:
        this.joinRejected(event.operatorUsername, event.reason);
        break;
      case EngagementServiceEvents.Kicked:
        this.onKicked();
        break;
      case EngagementServiceEvents.CRMUpdated:
        this.crmStateUpdated(event.crmData);
        break;
      case EngagementServiceEvents.DomSyncMessage:
        this.domSyncMessage$.next(event.message);
        break;
      case EngagementServiceEvents.DomSyncCommand:
        this.domSyncCommand$.next(event.message);
        break;
      case EngagementServiceEvents.AgentAssistAnswered:
        this.onAgentAssistAnswered();
        break;
      case EngagementServiceEvents.SwitchedCustomer:
        //this.onCustomerSwitch(event.switchedIDs);
        break;
      case EngagementServiceEvents.ChatSuggestion:
        this.onChatSuggestion(event.message);
        break;
      case EngagementServiceEvents.AppViewStartRequest:
        this.onAppViewStartRequest();
        break;
      case EngagementServiceEvents.VisitorNavigation:
        this.onVisitorNavigation(event.navigating);
        break;
      case EngagementServiceEvents.Disconnected:
        this.onEngagementDisconnected(event.reconnecting);
        break;
      case EngagementServiceEvents.UserVerified:
          this.onUserVerified(event.username, event.verified);
        break;
      case EngagementServiceEvents.UserConsentOtp:
        this.onUserConsentOtp(event.username, event.consent, event.to);
        break;
    }
  }

  public sendDomSyncCommand(message: string) {
    this.sendDomSyncCommand$.next(message);
  }

  private onChatMessageEvent(event: ChatMessageEvent) {
    if (event.message.Id === 1) {
      this.startMessage = this.messages.updateMessage(this.startMessage, {
        timestamp: new Date(event.message.TimeStamp.getTime() - 1),
      })
    }

    const senderType = this.textMessageSenderType(event.message);
    if (!event.fromPreviousChat && senderType === TextMessageTypes.ME) {
      return;
    }

    // only translate incoming message if from active chat and translation service is on
    // and its not html (we dont support html canned texts in BA and this will mean uploads wont be translated)
    if (this.translationOn$.value && !Engagement.isHTML(event.message.Message)) {
      this.translationService.translate(event, this.currentAgent, this.visitor)
        .subscribe(
          result => this.handleIncomingTranslation(result, event.fromPreviousChat),
          error => {
            this.handleTranslationError(error);
            this.chatMessage(event.message, event.fromPreviousChat);
          }
        );

    } else {
      this.chatMessage(event.message, event.fromPreviousChat);
    }
  }

  private handleIncomingTranslation(result: HubTextMessage, fromPreviousChat: boolean) {
    if (fromPreviousChat) {
      this.chatMessage(result, fromPreviousChat);
    } else {
      const resultIsCustomerTranslation: boolean = !result.SenderIsAgent && (result.OriginalMessage && result.OriginalMessage.length > 0);
      // only save customer traslation for primary agent
      if (resultIsCustomerTranslation && this.isPrimaryAgent()) {
        this.engagementHub.receiveTranslation(result);
      }
      this.chatMessage(result, fromPreviousChat);
    }
  }

  private handleTranslationError(error: Error) {
    this.translationOn$.next(false);
    this.logging.error('Translation turned off', error);
    this.alertService.addAlert(`Oops, something has gone wrong with the translation service, it is now disabled`, AlertType.Danger);
  }

  private chatMessage(message: HubTextMessage, fromPreviousChat: boolean) {
    const textMessage = this.createTextMessage(message);

    this._messages.addMessage(textMessage);

    if (textMessage.senderType !== TextMessageTypes.ME && !fromPreviousChat) {
      this.unreadMessages.next(this.unreadMessages.value + 1);
      this.newMessage.next({privateMessage: false, message: textMessage});
    }

    this.lastContactTime.next(new Date());
    this.visitorTypingMessage.next(null);
  }

  private createTextMessage(message: HubTextMessage): TextMessage {
    const senderType: TextMessageTypes = this.textMessageSenderType(message);
    const senderName: string = !message.SenderIsAgent ? this.visitor.customerName : message.SenderName;

    return {
      id: message.Id,
      senderName: senderName,
      senderId: message.SenderId,
      senderType: senderType,
      message: message.Message,
      timestamp: message.TimeStamp,
      originalMessage: message.OriginalMessage,
      currentEngagement: true,
      verified: this.hasCustomerVerification ? message.IsVerified : null,
    };
  }

  private textMessageSenderType(message: HubTextMessage): TextMessageTypes {
    if (!message.SenderIsAgent) {
      return message.SenderId === this.primaryCustomer.getValue() ? TextMessageTypes.CUSTOMER : TextMessageTypes.OTHER_CUSTOMER;
    } else if (message.SenderId === this.username) {
      return TextMessageTypes.ME;
    } else {
      return TextMessageTypes.OTHER_AGENT;
    }
  }

  private onPrivateChatMessage(message: HubTextMessage) {
    if (this.featureService.has(Features.PRIVATE_CHAT)) {
      const privateMessage = this.createTextMessage(message);
      this._privateMessages.push(privateMessage);
      this.privateMessages.next(this._privateMessages);
      if (privateMessage.senderType !== TextMessageTypes.ME) {
        this.unreadPrivateMessages.next(this.unreadPrivateMessages.value + 1);
        this.newMessage.next({privateMessage: true, message: privateMessage});
      }
    }
  }

  public setChatName(chatname: String) {
    this.chatName.next(chatname);
  }

  private visitorTyping(message: HubTextMessage) {
    this.visitorTypingMessage.next(this.createTextMessage(message));
  }

  private visitorEndedEngagement(visitor: EngagementHubVisitor) {
    this.visitorMissing.next(false);

    if (visitor.IsPrimary) {
      this.visitorEnded.next(true);
      this.engagementEnds();
    }
  }

  private onEngagementDisconnected(reconnecting: boolean) {
    if (reconnecting) {
      this.logging.info(`Disconnected from the engagement hub for engagement ${this.engagementId}, attempting to reconnect.`);
    } else {
      this.logging.error(`Unable to reconnect to engagement hub for engagement ${this.engagementId}.`);
      this.alertService.addResourceAlert('ENGAGEMENT_ENDED_DISCONNECTED', 'Unable to reconnect to engagement hub.');
      this.engagementEnds();
    }
  }

  private onUserVerified(userguid: string, verified: boolean) {
    if (verified) {
      this.alertService.addResourceAlert('USER_VERIFIED_ALERT', 'User Verified', AlertType.Success);
    } else {
      this.alertService.addResourceAlert('USER_UNVERIFIED_ALERT', 'User Not Verified', AlertType.Danger);
    }
  }

  private onUserConsentOtp(userguid: string, consent: boolean, to: string) {
    if (consent) {
      this.engagementHub.sendOtp(userguid, to);
    } else {
      this.alertService.addResourceAlert('USER_CONSENT_ALERT', 'User Rejected Consent', AlertType.Danger);
    }
  }

  private agentLocksCall(isLocked: boolean) {
    // This is used to "lock" the agents in a call when a primary agent is ending it
    // so that they can choose an agent to make the new primary.

    // Ignore this is we are the primary agent
    if (this.isPrimaryAgent()) {
      return;
    }

    if (isLocked) {
      // If we are doing the lock for the first time then set it
      // otherwise ignore it.
      if (!this.agentLockTimerSub) {
        this.lockRoomForAgent();
      }
    } else {
      // If we are unlocked then clear everything and unlock the room
      this.unlockRoomForAgent();
    }
  }

  private lockRoomForAgent() {
    const lockTimer = timer(Engagement.ROOM_AGENT_LOCK_TIMEOUT_MS);
    this.agentLockTimerSub = lockTimer.subscribe(() => {
      this.unlockRoomForAgent();
    });
  }

  private unlockRoomForAgent() {
    if (this.agentLockTimerSub) {
      this.agentLockTimerSub.unsubscribe();
      this.agentLockTimerSub = undefined;
    }
  }

  private agentEndsCall() {
    this.engagementHub.setConnectionInfo(null);
    this.unlockRoomForAgent();

    if (!this.isPrimaryAgent()) {
      this.engagementEnds();
    }
  }

  private updateRoom(newRoom: Room) {
    const visitorsLength = newRoom.Visitors.length;

    if (visitorsLength > 0) {
      this.currentRoomTime = newRoom.CurrentTime;
      this.primaryAgent.next(newRoom.PrimaryAgent);
      this.presentingAgent.next(newRoom.PresentingAgent);
      this.currentPage.next(newRoom.CurrentPage);
      this.privateChatEnabled.next(newRoom.PrivateChatEnabled);
      this.agentAssistBotAutosendMessage.next(newRoom.AgentAssistBotAutosendMessage);
      this.agentAssistBotGetSuggestions.next(newRoom.AgentAssistBotGetSuggestions);
      this.currentFeedId$.next(newRoom.CurrentFeedId);
      this.currentFeedType$.next(newRoom.CurrentFeedType);

      if (this.webrtcIceServer == null || this.webrtcIceServer.length === 0) {
        this.authenticatedIceUrl$.next(newRoom.AuthenticatedIceUrl);
        this.webrtcIceServer = newRoom.AuthenticatedIceUrl;
      }
      this.engagementUrl$.next(`${environment.engageHub}/${newRoom.RoomNumber}`)
      this.primaryCustomer.next(newRoom.PrimaryCustomer);
      this.isDomSync$.next(newRoom.SyncDom);

      this.updateVisitorStreams(newRoom);
      this.checkPrimaryVisitorIsOnline();

    } else if (!this.engagementEnded.value) {
      if (this.isPrimaryAgent()) {
        this.primaryVisitor$.next(null);
        this.setVisitorDisconnected();
      }
    }

    this.updateAgents(newRoom.Agents);
    this.updateVisitors(newRoom);

    //FIXME: this needs to be based on the room status property
    this.roomFull$.next(this.roomAgents.size >= 2 || this.roomVisitors.size >= 2);
  }

  private checkPrimaryVisitorIsOnline() {
     const primaryVisitor = this.primaryVisitor$.getValue();
     const visitorMissing = this.visitorMissing.getValue();
      if (primaryVisitor?.isOnline) {
        if (this.isPrimaryAgent() && visitorMissing) {
          this.visitorMissing.next(false);
        }
      } else {
        this.setVisitorDisconnected();
      }
  }

  private updateVisitorStreams(newRoom: Room) {
    var previousVisitorGuids: string[] = [];
    for (const visitor of this._roomVisitors.values()) {
      previousVisitorGuids.push(visitor.sessionGuid);
    }

    // connect / disconnect the visitors
    // first connect all that the server knows about
    for (var i = 0; i < newRoom.Visitors.length; ++i) {
      const newVisitorMessage: WebrtcMessageAddStream = {
        type: WebrtcMessageType.AddStream,
        peerType: 'visitor',
        streamName: newRoom.Visitors[i].SessionGuid.toString(),
      };
      this.webrtcMessages.next(newVisitorMessage);
      // remove this visitor from our temporary array
      if (previousVisitorGuids.includes(newRoom.Visitors[i].SessionGuid)) {
        previousVisitorGuids.splice(previousVisitorGuids.indexOf(newRoom.Visitors[i].SessionGuid), 1);
      }
    }
    // what is left in the temparray should be removed/disconnected
    for (var i = 0; i < previousVisitorGuids.length; ++i) {
      const removeVisitorMessage: WebrtcMessageRemoveStream = {
        type: WebrtcMessageType.RemoveStream,
        streamName: previousVisitorGuids[i].toString(),
      };
      this.webrtcMessages.next(removeVisitorMessage);
    }
  }

  private updateAgents(agents: HubAgent[]) {
    const oldRoomAgentNames: string[] = [];

    if (agents.length !== this._roomAgents.size) {
      // Do a full update because lengths have changed, so just delete everything.
      // Has the problem of one person leaving and another one joining between updates
      // but with the current invite system that can not happen so ignoring as a pathological
      // case.

      for (const agentName of this._roomAgents.keys()) {
        oldRoomAgentNames.push(agentName);
      }

      this._roomAgents.clear();
    }

    const now = new Date().getTime();

    for (const agent of agents) {
      const oldAgent = this._roomAgents.get(agent.UserName);

      if (oldAgent) {
        // Just update the old agent
        oldAgent.lastUpdatedMS = Math.max(0, now - new Date(agent.LastUpdateTime).getTime());
      } else {
        // Make a new agent
        const newAgent: EngagementAgent = {
          username: agent.UserName,
          nickname: agent.NickName,
          lastUpdatedMS: 0,
          photo: environment.assetProxy + agent.Photo,
        };

        this._roomAgents.set(agent.UserName, newAgent);

        if (newAgent.username !== this.username) {
          // Add this agents stream as well
          const newAgentMessage: WebrtcMessageAddStream = {
            type: WebrtcMessageType.AddStream,
            peerType: 'Agent',
            streamName: agent.UserName,
          };
          this.webrtcMessages.next(newAgentMessage);
        }
      }
    }

    for (const oldRoomAgentName of oldRoomAgentNames) {
      if (!this._roomAgents.has(oldRoomAgentName)) {
        const removeWebrtcMessage: WebrtcMessageRemoveStream = {
          type: WebrtcMessageType.RemoveStream,
          streamName: oldRoomAgentName,
        };
        this.webrtcMessages.next(removeWebrtcMessage);
      }
    }
  }

  private updateVisitors(newRoom: Room) {
    const visitors: EngagementHubVisitor[] = newRoom.Visitors;
    const visitorMap: Map<string, EngagementHubVisitor> = new Map(visitors.map(visitor => [visitor.UserGuid, visitor]));

    // Update existing visitors / remove those that have left
    for (const [userGuid, roomVisitor] of this._roomVisitors) {
      const hubVisitor = visitorMap.get(userGuid);

      if (!hubVisitor) {
        this._roomVisitors.delete(userGuid);
        this.visitorLeft$.next(roomVisitor);
      } else {
        roomVisitor.update(this.currentRoomTime, hubVisitor);
      }
    }

    // Add new visitors who are not already in _roomVisitors
    for (const [userGuid, hubVisitor] of visitorMap) {
      if (!this._roomVisitors.has(userGuid)) {
        const visitor = this.createOrUpdateVisitor(hubVisitor);
        if (visitor.isPrimary) {
          this.primaryVisitor$.next(visitor);
          this.primaryCustomer.next(visitor.userGuid);
        }
      }
    }
  }

  private createOrUpdateVisitor(hubVisitor: EngagementHubVisitor): EngagementVisitor {
    const userGuidStr = hubVisitor.UserGuid.toString();
    let visitor = this._roomVisitors.get(userGuidStr);

    if (!visitor) {
      visitor = new EngagementVisitor(hubVisitor);
      this._roomVisitors.set(userGuidStr, visitor);
      this.visitorJoined$.next(visitor);
    }

    visitor.update(this.currentRoomTime, hubVisitor);
    return visitor;
  }


  private setVisitorDisconnected() {
    this.visitorMissing.next(true);
  }

  public sendWebrtcMessage(message: any) {
    // TODO: 2020-10-20 - Remove this hack when SDKs are fixed
    // This is a hack to prevent the mobile SDK being sent messages for webrtc sharing
    // The actual fix should be in the SDKs so that it checks the "signalType" parameter
    // before consuming the message. What is currently happening in the SDKs (pre-11.2.0
    // release timeframe) is that they are getting the webrtc message for 'new'
    // ice candidates and then that is causing the outbound connection to restart.
    // On android you get lots of errors but it happily continues, on iOS you run out of
    // file descriptors and after ~45s the customer AV stops.
    if (this.visitor.isMobileSdk && message.indexOf('share') > -1) {
      return;
    } else {
      this.engagementHub.sendWebrtcMessage(message);
    }
  }

  private webrtcSignalling(message: any) {
    const msg: WebrtcMessageSignalling = {
      type: WebrtcMessageType.SignallingMessage,
      message,
    };

    this.webrtcMessages.next(msg);
  }

  private updatePrimaryVisitor(visitor: EngagementVisitor) {
    if (!visitor?.stateInitialised) {
      this.logging.info(`Not updating visitor state for ${visitor.userGuid} as it has not been init by the visitor (StateInitialised == false)`);
      return;
    }

    this.primaryCustomer.next(visitor.userGuid);

    this.textVisible.next(visitor?.state?.textVisible);
    this.videoVisible.next(visitor?.state?.videoVisible);

    this.cameraEnabled.next(visitor?.state?.cameraEnabled);
    this.micEnabled.next(visitor?.state?.micEnabled);
    this.customerCogOn.next(visitor?.state?.devicesVisible);

    this.panelFullSize.next(visitor?.state?.panelFullSize);
    this.engagementMode.next(<EngagementMode>(visitor?.state?.engagementMode || ''));

    this.viewingOperatorStream.next(visitor?.state?.viewingOperatorStream);
    this.hearingOperatorStream.next(visitor?.state?.hearingOperatorStream);

    this.micGain.next(visitor?.state?.micGain);
    this.speakerVolume.next(visitor?.state?.speakerVolume);

    this.callPaused.next(visitor?.state?.callPaused);
    this.visitorIsSharing.next(visitor?.state?.isSharing);

    this.cameraAccessGranted.next(visitor?.state?.cameraAccessGranted);
    this.cameraAlreadyInUse.next(visitor?.state?.cameraAlreadyInUse);
    this.micAccessGranted.next(visitor?.state?.micAccessGranted);
    this.micAlreadyInUse.next(visitor?.state?.micAlreadyInUse);
    this.micActivityLevel.next(visitor?.state?.micActivityLevel);
    this.backgrounded.next(visitor?.isBackgrounded);

    this.chatOnly.next(visitor?.chatOnly);

    //console.log(`########## communicationMode >>>${visitor.State.communicationMode}<<<`);
    this.communicationMode.next(visitor?.state?.communicationMode);

    this._panelPositionAndSize.next({
      browserWidth: visitor?.state?.clientSizeX,
      browserHeight: visitor?.state?.clientSizeY,
      size: visitor?.state?.panelSize,
      panelDimensions: visitor?.state?.panelPositionAndSize,
      videoSize: visitor?.state?.videoSize,
    });

    this.panelSize.next(visitor?.state?.panelSize);

    this.panelVisible.next(visitor?.state?.panelVisible);

    if (this.visitor.isMobileSdk) {
      this.isBrowserVisible.next(visitor?.state?.SDK.isBrowserVisible);
      this.isAppView.next(visitor?.state?.SDK.isSendingScreen);
      this.isInBackground.next(!visitor?.state?.SDK.inForeground);
      this._cameraDirection = visitor?.state?.SDK.cameraDirection === 'f' ? CameraDirection.FRONT : CameraDirection.REAR;

      this.browserSize.next({height: visitor?.state?.SDK.browserHeight, width: visitor?.state?.SDK.browserWidth});
      this.sharingSize.next({height: visitor?.state?.SDK.sharingHeight, width: visitor?.state?.SDK.sharingWidth});
      this.screenSize.next({height: visitor?.state?.SDK.screenHeight, width: visitor?.state?.SDK.screenWidth});

      this._panelPositionAndSize.next({
        browserWidth: visitor?.state?.SDK.browserWidth,
        browserHeight: visitor?.state?.SDK.browserHeight,
        size: visitor?.state?.panelSize,
        panelDimensions: visitor?.state?.panelPositionAndSize,
      });
    }

  }

  public agentDisconnected() {
    if (this.isPrimaryAgent()) {
      this.endChat();
    } else {
      this.leaveChat();
    }
  }

  public endChat(): Promise<void> {
    return this.engagementHub
      .endChat(this.currentPage.getValue())
      .catch((err) => {
        this.logging.error('Error ending chat. Connection will still be disposed.', err);
      })
      .finally(() => {
        setTimeout(() => this.engagementEnds(), 0);
      });
  }

  public leaveChat(): boolean {
    if (this.roomLocked) {
      return false;
    } else {
      setTimeout(() => this.engagementEnds(), 0);
      return true;
    }
  }

  public switchCoBrowseOn(val: boolean) {
    this.allowBrowsing.next(val);
    this.engagementHub.setRoomProperty("SyncBrowser", val.toString())
      .catch(err => this.logging.warn(`Unable to set roomProperty SyncBrowser`, err));
  }

  public showPage(newPageUrl: string, force: boolean = false) {
    if (this.currentPage.value != newPageUrl || force) {
      if (this.allowBrowsing.getValue() && !this.visitorIsSharing.getValue() && !this.domSyncOn$.getValue() || force) {
        if (this.isPresentingAgent()) {
          this.engagementHub.showPage(newPageUrl)
            .catch(err => {
              this.logging.warn(`Unable to set page to ${newPageUrl}`, err);
            });
        }
      }
    }
  }

  public pauseCall(pause: boolean) {
    this.videoPaused.next(pause);
    this.engagementHub.pauseCall(pause)
      .catch(err => this.logging.warn(`Unable to send pause command in engagement ${this.engagementId}`, err));
  }

  // true for pause, false for resume
  public togglePauseCall() {
    if (this.callPaused.getValue()) {
      this.videoPaused.next(false);
      this.engagementHub.pauseCall(false)
        .catch(err => this.logging.warn(`Unable to send pause(true) command in engagement ${this.engagementId}`, err));
    } else {
      this.videoPaused.next(true);
      this.engagementHub.pauseCall(true)
        .catch(err => this.logging.warn(`Unable to send pause(false) command in engagement ${this.engagementId}`, err));
    }
  }

  public controlInternalApp(start: boolean) {
    this.engagementHub.controlInternalApp(start);
  }

  public setMicGain(newVolume: number) {
    this.engagementHub.setMicGain(newVolume);
  }

  public setSpeakerVolume(newVolume: number) {
    if (this.visitor.isMobileSdk) {
      // The SDKs are set up to use the hear/mute op instead of speaker volumes
      if (newVolume > 0) {
        this.engagementHub.hearOp()
          .catch(err => this.logging.warn(`Unable to send hearOp command in engagement ${this.engagementId}`, err));
      } else {
        this.engagementHub.muteOp()
          .catch(err => this.logging.warn(`Unable to send muteOp command in engagement ${this.engagementId}`, err));
      }
    } else {
      this.engagementHub.setSpeakerVolume(newVolume)
        .catch(err => this.logging.warn(`Unable to send setSpeakerVolume command in engagement ${this.engagementId}`, err));
    }
  }

  public switchToTextMode() {
    this.engagementHub.switchToTextMode()
      .catch(err => this.logging.warn(`Unable to send switchToTextMode command in engagement ${this.engagementId}`, err));
  }

  public switchToAudioMode() {
    this.engagementHub.switchToAudioMode()
      .catch(err => this.logging.warn(`Unable to send switchToAudioMode command in engagement ${this.engagementId}`, err));
  }

  public switchToVideoMode() {
    this.engagementHub.switchToVideoMode()
      .catch(err => this.logging.warn(`Unable to send switchToVideoMode command in engagement ${this.engagementId}`, err));
  }

  public setPanelSize(size: PanelSize) {
    switch (size) {
      default:
      case PanelSize.Normal:
        this.engagementHub.setPanelSizeNormal()
          .catch(err => this.logging.warn(`Unable to send setPanelSizeNormal command in engagement ${this.engagementId}`, err));
        break;
      case PanelSize.Small:
        this.engagementHub.setPanelSizeSmall()
          .catch(err => this.logging.warn(`Unable to send setPanelSizeSmall command in engagement ${this.engagementId}`, err));
        break;
      case PanelSize.Big:
        this.engagementHub.setPanelSizeBig()
          .catch(err => this.logging.warn(`Unable to send setPanelSizeBig command in engagement ${this.engagementId}`, err));
        break;
      case PanelSize.HD:
        this.engagementHub.setPanelSizeHD()
          .catch(err => this.logging.warn(`Unable to send setPanelSizeHD command in engagement ${this.engagementId}`, err));
        break;
    }
  }

  public setVideoSize(size: PanelSize) {
    switch (size) {
      default:
      case PanelSize.Normal:
        this.engagementHub.setVideoSizeNormal()
          .catch(err => this.logging.warn(`Unable to send setVideoSizeNormal command in engagement ${this.engagementId}`, err));
        break;
      case PanelSize.Small:
        this.engagementHub.setVideoSizeSmall()
          .catch(err => this.logging.warn(`Unable to send setVideoSizeSmall command in engagement ${this.engagementId}`, err));
        break;
      case PanelSize.Big:
        this.engagementHub.setVideoSizeBig()
          .catch(err => this.logging.warn(`Unable to send setVideoSizeBig command in engagement ${this.engagementId}`, err));
        break;
      case PanelSize.HD:
        this.engagementHub.setVideoSizeHD()
          .catch(err => this.logging.warn(`Unable to send setVideoSizeHD command in engagement ${this.engagementId}`, err));
        break;
    }
  }

  public setPanelPosition(hAlign: string, vAlign: string) {
    this.engagementHub.setPanelPosition(hAlign, vAlign)
      .catch(err => this.logging.warn(`Unable to send setPanelPosition command in engagement ${this.engagementId}`, err));
  }

  public moveComsPanel() {
    throw new Error('Not implemented!!!');
  }

  public positionVideo(hAlign: string, vAlign: string) {
    this.engagementHub.positionVideo(hAlign, vAlign)
      .catch(err => this.logging.warn(`Unable to send positionVideo command in engagement ${this.engagementId}`, err));
  }

  public setTextVisible(show: boolean) {
    this.engagementHub.showText(show)
      .catch(err => this.logging.warn(`Unable to send showText command in engagement ${this.engagementId}`, err));
  }

  public setPanelVisible(show: boolean) {
    this.engagementHub.showPanel(show)
      .catch(err => this.logging.warn(`Unable to send setPanelVisible command in engagement ${this.engagementId}`, err));
  }

  public setSharingOn(on: boolean, message: string) {

    if (on) {
      this.engagementHub.startSharing(message);
      this.engagementHub.setRoomProperty("SharingMode", "mainScreen")
        .catch(err => this.logging.warn(`Unable to send setRoomProperty SharingMode in engagement ${this.engagementId}`, err));
    } else {
      if (this.visitor.isMobileSdk) {
        this.engagementHub.stopSharing()
          .catch(err => this.logging.warn(`Unable to send stopSharing command in engagement ${this.engagementId}`, err));
      } else {
        this.engagementHub.showPage(message)
          .catch(err => this.logging.warn(`Unable to send showPage command in engagement ${this.engagementId}`, err));
      }
    }

    this.engagementHub.setRoomProperty("Sharing", on.toString())
      .catch(err => this.logging.warn(`Unable to send setRoomProperty Sharing in engagement ${this.engagementId}`, err));
  }

  public opTyping() {
    this.engagementHub.opTyping()
      .catch(err => this.logging.warn(`Unable to send opTyping command in engagement ${this.engagementId}`, err));
  }

  public opDeletedTyping() {
    this.engagementHub.opDeletedTyping()
      .catch(err => this.logging.warn(`Unable to send opDeletedTyping command in engagement ${this.engagementId}`, err));
  }

  // Show video on responsive panels
  public showVideo(show: boolean) {
    this.engagementHub.showVideo(show)
      .catch(err => this.logging.warn(`Unable to send showVideo command in engagement ${this.engagementId}`, err));
  }

  public startCamera(start: boolean, username: string) {
    this.engagementHub.startCamera(start, username)
      .catch(err => this.logging.warn(`Unable to send startCamera command in engagement ${this.engagementId} - ${username}`, err));
  }

  public startMic(start: boolean, username: string) {
    this.engagementHub.startMic(start, username)
      .catch(err => this.logging.warn(`Unable to send startMic command in engagement ${this.engagementId} - ${username}`, err));
  }

  public enablePrivateChat() {
    this.engagementHub.enablePrivateChat()
      .catch(err => this.logging.warn(`Unable to send enablePrivateChat command in engagement ${this.engagementId}`, err));
  }

  public remotePanelShowDevices(start: boolean, username?: string) {
    this.engagementHub.remotePanelShowDevices(start, username)
      .catch(err => this.logging.warn(`Unable to send remotePanelShowDevices command in engagement ${this.engagementId} - ${username}`, err));
  }

  public setTranslationEnabled(enabled: boolean) {
    this.translationOn$.next(enabled);
  }

  public setBlockedOn() {
    this.blockedOn$.next(true);
  }

  public setGetSuggestionEnabled(enabled: boolean) {
    this.engagementHub.enableAgentAssistBotGetSuggestions(enabled)
      .catch(err => this.logging.warn(`Unable to send enableAgentAssistBotGetSuggestions command in engagement ${this.engagementId}`, err));
  }

  public setAutoReplyEnabled(enabled: boolean) {
    this.engagementHub.enableAgentAssistBotAutosendMessage(enabled)
      .catch(err => this.logging.warn(`Unable to send enableAgentAssistBotAutosendMessage command in engagement ${this.engagementId}`, err));
  }

  public sendFileUploadMessage(message: string) {
    this.unreadMessages.next(0);
    this.agentText = '';
    this.engagementHub.sendFileUploadMessage(message)
      .then(textMessage => this.chatMessage(textMessage, false));
  }

  public sendChatMessage(message: string) {
    this.unreadMessages.next(0);
    this.agentText = '';

    if (this.translationOn$.value) {
      this.translationService.requestTranslation(message, this.currentAgent.culture, this.visitor.culture, this.currentAgent.authToken)
        .subscribe(
          response => this.handleOutgoingTranslation(response, message),
          error => {
            this.handleTranslationError(error);
            this.sendChatMessage(message);
          }
        );

    } else {
      this.sendChatMessageToHub(message, this.currentAgent.culture, '');
    }
  }

  private addTemporaryMessage(message: string): TextMessage {
    const textMessage: TextMessage = {
      id: -1,
      senderName: this.currentAgent.nickname,
      senderId: this._username,
      senderType: TextMessageTypes.ME,
      message: message,
      timestamp: new Date(),
      originalMessage: '',
      deliveryStatus: DeliveryStatus.Sending,
      deliveryTimestamp: new Date(),
      currentEngagement: true,
      verified: null
    };
    this._messages.addMessage(textMessage);
    return textMessage;
  }

  private removeTemporaryMessage(textMessage: TextMessage, chatMessage: HubTextMessage) {
    this._messages.removeMessage(textMessage);
    this.chatMessage(chatMessage, false);
  }

  private setTemporaryMessageToFailed(textMessage: TextMessage) {
    this.logging.error(`Failed to send chat message for engagement ${this._engagementId}`);
    if (this._messages.updateMessage(textMessage, {
      deliveryStatus: DeliveryStatus.Failed,
      deliveryTimestamp: new Date()
    })) {
      this.logging.debug(`Updated temporary message ${textMessage.id} for engagement ${this._engagementId}`);
    } else {
      this.logging.warn(`Unable to find temporary message ${textMessage.id} to update for engagement ${this._engagementId}`);
    }
  }

  public addInternalMessage(text: string) {
    const msgLength = this._messages.length;
    const id = ((msgLength > 0) ? this._messages.messages[msgLength - 1].id : 0) + 0.5;
    const message: TextMessage = {
      id,
      senderName: '',
      senderId: '',
      senderType: TextMessageTypes.INTERNAL_MESSAGE,
      message: text,
      timestamp: new Date(),
      originalMessage: text,
      currentEngagement: true,
      verified: null
    };
    this._messages.addMessage(message);
  }

  private handleOutgoingTranslation(response: Translation, message: string) {
    return this.sendChatMessageToHub(
      response.translatedMessage,
      response.hasTranslation ? this.visitor.culture : this.currentAgent.culture,
      response.hasTranslation ? message : ''
    ).catch(err => this.logging.warn(`Unable to send sendChatMessage command in engagement ${this.engagementId}`, err));
  }

  private sendChatMessageToHub(message: string, culture: string, originalMessage: string) {
    const textMessage = this.addTemporaryMessage(message);
    return this.engagementHub.sendChatMessage(message, culture, originalMessage)
      .then((chatMessage) => {
        this.removeTemporaryMessage(textMessage, chatMessage);
        return Promise.resolve(chatMessage);
      }).catch((err) => {
        this.logging.error(`Unable to send chat message for engagement ${this.engagementId}`, err);
        this.setTemporaryMessageToFailed(textMessage);
      });
  }

  public sendPrivateChatMessage(message: string) {
    this.unreadPrivateMessages.next(0);
    this.engagementHub.sendPrivateChatMessage(message)
      .catch(err => this.logging.warn(`Unable to send sendPrivateChatMessage command in engagement ${this.engagementId}`, err));
    this.lastContactTime.next(new Date());
  }

  public resetUnreadMessages() {
    this.unreadMessages.next(0);
  }

  public resetUnreadPrivateMessages() {
    this.unreadPrivateMessages.next(0);
  }

  public hasVisitor(visitorId: string): boolean {
    return this.visitor.userGuid === visitorId;
  }

  public onConnected() {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    const connInfo: () => ConnectionInfo = () => {
      return {
        callType: this.callType,
        opImage: this.options.widephoto,
        operatorNickname: this.options.nickname,
        webrtcIceServer: this.webrtcIceServer,
      };
    };

    switch (this.currentState.value.type) {
      case EngagementState.Idle: {
        switch (this.currentState.value.startState) {
          case EngagementStartState.Normal:
            this.engagementHub.setConnectionInfo(connInfo());
            break;
          case EngagementStartState.Join:
            this.onJoinAccepted();
            break;
          case EngagementStartState.Transfer:
            this.engagementHub.setConnectionInfo(connInfo());
            this.onTransferringInAccepted();
            break;
          case EngagementStartState.Supervisor:
            this.onSupervisorJoined();
            break;
          case EngagementStartState.Rejoin:
            this.onRejoinExistingEngagement();
            break;
        }

      }
        break;
      default:
        this.currentState.next(Engagement.STATE_ENDED);
        throw new Error('Invalid state, how did this happen');
    }

    if (this.featureService.has(Features.TRANSLATOR)) {
      this.translationOn$.next(true);
    }

    if (this.featureService.has(Features.AGENT_ASSIST)) {
      this.engagementHub.enableAgentAssistBotForRoom(true)
        .catch(err => this.logging.warn(`Unable to send enableAgentAssistBotForRoom command in engagement ${this.engagementId}`, err));
      this.engagementHub.enableAgentAssistBotGetSuggestions(true)
        .catch(err => this.logging.warn(`Unable to send enableAgentAssistBotGetSuggestions command in engagement ${this.engagementId}`, err));
      this.engagementHub.enableAgentAssistBotAutosendMessage(this.settingService.getSiteSettingOrDefault("DisplayAutosendButtons", "false").toLowerCase() == "true" && this.settingService.getSiteSettingOrDefault("DefaultAutosendValue", "false").toLowerCase() == "true")
        .catch(err => this.logging.warn(`Unable to send enableAgentAssistBotAutosendMessage command in engagement ${this.engagementId}`, err));
    }

    this.enforceAgentPermissions();

    this.currentState.next(Engagement.STATE_ENGAGED);

    this.subscriptions.push(
      this.roomFull$.pipe(distinctUntilChanged(), filter(v => v)).subscribe(() => {


        switch (this.currentState.getValue().type) {
          case EngagementState.Join:
            this.cancelJoin();
            break;
          case EngagementState.Transfer:
            this.cancelTransfer();
            break;
        }

      })
    );

    this.subscriptions.push(
      this.onPrimaryCustomerChanged$.subscribe(newGuid => {

        this._roomVisitors.delete(this.visitor.userGuid);
        this.visitor.userGuid = newGuid;

        this.crmService.loadCustomerData(newGuid).pipe(take(1)).subscribe(data => {
          const crmData = this.crmService.extractCustomerData(data);
          this.updateCustomerName(this.visitor.userGuid, this.getCustomerName(crmData));
          this.visitor.setCrmData(data, this.crmService);
        });

        this.crmService.getSessionHistory(newGuid).pipe(take(1)).subscribe(data => {
          this.visitor.sessionHistory.next(data);
          this.chatHistory.updateSessionHistory(data);
        });

      }));
  }

  public uploadFile(file: File): boolean {
    const upload = this.fileTransferService.createFileTransfer(file, this.visitor.userGuid, this.visitor.sessionGuid, this.engagementId.toString());

    if (upload) {
      const sub = upload.state.subscribe(newState => this.handleTransferState(newState, upload));
      this.fileTransfers.set(upload.transferId, upload);
      this.fileTransferSubs.set(upload.transferId, sub);
      this.fileTransfers$.next(this.fileTransfers);
      return this.fileTransferService.start(upload);
    }

    return false;
  }

  public removeFileUpload(transfer: IFileUpload): boolean {
    const removed = this.fileTransfers.delete(transfer.transferId);

    if (removed) {
      this.fileTransfers$.next(this.fileTransfers);
      return this.fileTransferService.cancel(transfer);
    }

    return false;
  }

  private handleTransferState(newState: TransferState, recvUpload: IFileUpload) {
    const upload = this.fileTransfers.get(recvUpload.transferId);

    if (!upload) {
      // Ignore uploads that we may have removed for other reasons
      return;
    }

    switch (newState) {
      case TransferState.IDLE:
        break;
      case TransferState.INPROGRESS:
        break;
      case TransferState.CANCELLED: {
        const sub = this.fileTransferSubs.get(upload.transferId);
        if (sub) {
          sub.unsubscribe();
          this.fileTransferSubs.delete(upload.transferId);
        }
      }

        break;
      case TransferState.ERRORED: {
        const sub = this.fileTransferSubs.get(upload.transferId);
        if (sub) {
          sub.unsubscribe();
          this.fileTransferSubs.delete(upload.transferId);
        }
      }
        break;
      case TransferState.DONE: {
        const sub = this.fileTransferSubs.get(upload.transferId);
        if (sub) {
          sub.unsubscribe();
          this.fileTransferSubs.delete(upload.transferId);
        }
      }
        this.fileTransfers.delete(upload.transferId);

        this.fileTransfers$.next(this.fileTransfers);
        //TODO: fix this.  Move into own component
        const message = '<a target="_blank" href="' + upload.uploadUrl + '">' + upload.filename + '</a>';
        this.sendFileUploadMessage(message);
        break;
    }
  }

  private onJoinAccepted() {

  }

  private onTransferringInAccepted() {
    const callType = this.callType;

    let message = this.engagementId + ':' +
      this.options.widephoto + ':' +
      this.options.nickname + ':' +
      this.options.username + ':' +
      callType + ':' +
      this.webrtcIceServer;

    this.engagementHub.transferringInAccepted(message)
      .catch(err => this.logging.warn(`Unable to send transferringInAccepted command in engagement ${this.engagementId}`, err));
  }

  private engagementEnds() {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    this.unlockRoomForAgent();

    this.engagementEnded.next(true);
    this.engagementEnded.complete();

    this.visitorMissing.complete();
    this.visitorEnded.complete();

    this.visitorJoined$.complete();
    this.visitorLeft$.complete();

    this.currentPage.complete();

    const state = this.currentState.value;

    // Delete all of the subscriptions and file transfers
    for (const [_, sub] of this.fileTransferSubs) {
      sub.unsubscribe();
    }
    this.fileTransferSubs.clear();

    for (const [_, upload] of this.fileTransfers) {
      this.fileTransferService.cancel(upload);
    }
    this.fileTransfers.clear();
    this.fileTransfers$.next(this.fileTransfers);

    switch (state.type) {
      case EngagementState.Transfer: {
        switch (state.inviteState) {
          case CallInviteState.InviteWaiting:
            this.engagementHub.abortTransferOutRequest(this.options.sitename, state.operatorUsername)
              .catch(err => this.logging.warn(`Unable to send abortTransferOutRequest command in engagement ${this.engagementId}`, err));
          // fall thru
          case CallInviteState.InviteSelecting:
          // fall thru
          case CallInviteState.InviteRejected:
            this.logging.info(`Attempting switch from ${state} to ${EngagementState.Post}`);
            this.currentState.next(Engagement.STATE_POST);
            break;
          case CallInviteState.InviteAccepted:
            this.currentState.next(Engagement.STATE_ENDED);
            this.currentState.complete();
            break;
        }
      }
        break;

      case EngagementState.Join: {
        switch (state.inviteState) {
          case CallInviteState.InviteWaiting:
            this.abortJoinRequest(state.operatorUsername);
          // fall thru
          case CallInviteState.InviteSelecting:
          // fall thru
          case CallInviteState.InviteRejected:
            this.logging.info(`Attempting switch from ${state} to ${EngagementState.Post}`);
            this.currentState.next(Engagement.STATE_POST);
            break;
          case CallInviteState.InviteAccepted:
            this.currentState.next(Engagement.STATE_ENDED);
            this.currentState.complete();
            break;
        }
      }
        break;

      case EngagementState.Engaged:
        if (this.isPrimaryAgent()) {
          this.logging.info(`Switching to post due to engagement end as primary`);
          this.currentState.next(Engagement.STATE_POST);
        } else {
          this.logging.info(`Going to engagement ended due to engagement end as non-primary`);
          this.currentState.next(Engagement.STATE_ENDED);
        }
        break;
      case EngagementState.Idle:
        this.logging.error('Engagement state idle when ending');
        this.currentState.next(Engagement.STATE_ENDED);
        this.currentState.complete();
        break;

      default:
        throw new Error('Invalid state transition');
    }

    this.dispose();
  }

  public postCompleted() {
    this.currentState.next(Engagement.STATE_ENDED);
    this.currentState.complete();
  }

  public selectJoiningOperator() {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    const state = this.currentState.value;

    switch (state.type) {
      case EngagementState.Join: {
        switch (state.inviteState) {
          case CallInviteState.InviteRejected:
          case CallInviteState.InviteSelecting:
            break;
          case CallInviteState.InviteWaiting:
            throw new Error('Invalid state transition');
        }
      }
        break;

      case EngagementState.Engaged:
        this.logging.info(`Attempting switch from ${state} to EngagementState.JoinSelecting`);
        this.currentState.next(Engagement.STATE_JOIN_SELECTING);
        break;
      default:
        throw new Error('Invalid state transition');
    }
  }

  public cancelJoin() {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    const state = this.currentState.value;

    switch (state.type) {
      case EngagementState.Join: {
        switch (state.inviteState) {
          case CallInviteState.InviteSelecting:
            this.currentState.next(Engagement.STATE_ENGAGED);
            break;
          case CallInviteState.InviteWaiting:
            this.abortJoinRequest(state.operatorUsername);
          // fall thru
          case CallInviteState.InviteRejected:
            if (this.roomFull$.getValue()) {
              this.currentState.next(Engagement.STATE_ENGAGED);
            } else {
              this.currentState.next(Engagement.STATE_JOIN_SELECTING);
            }

            break;
          default:
            throw new Error('Invalid state transition');
        }
      }
        break;
      default:
        throw new Error('Invalid state transition');
    }
  }

  public selectTransferringOperator() {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    // Early return if we are not the primary agent.
    if (!this.isPrimaryAgent()) {
      return;
    }

    const state = this.currentState.value;

    switch (state.type) {
      case EngagementState.Transfer: {
        switch (state.inviteState) {
          case CallInviteState.InviteRejected:
          case CallInviteState.InviteSelecting:
            break;
          case CallInviteState.InviteWaiting:
            throw new Error('Invalid state transition');
        }
      }
        break;

      case EngagementState.Engaged:
        this.logging.info(`Attempting switch from ${state} to EngagementState.TransferSelecting`);
        this.currentState.next(Engagement.STATE_TRANSFER_SELECTING);
        break;
      default:
        throw new Error('Invalid state transition');
    }
  }

  public cancelTransfer() {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    const state = this.currentState.value;

    switch (state.type) {
      case EngagementState.Transfer: {
        switch (state.inviteState) {
          case CallInviteState.InviteSelecting:
            this.currentState.next(Engagement.STATE_ENGAGED);
            break;
          case CallInviteState.InviteWaiting:
            this.engagementHub.abortTransferOutRequest(this.options.sitename, state.operatorUsername)
              .catch(err => this.logging.warn(`Unable to send abortTransferOutRequest command in engagement ${this.engagementId}`, err));
          // fall thru
          case CallInviteState.InviteRejected:
            this.engagementHub.notifyCustomerTransferWasCancelled(btoa('Transfer Cancelled by the Agent'))
              .catch(err => this.logging.warn(`Unable to send notifyCustomerTransferWasCancelled command in engagement ${this.engagementId}`, err));

            if (this.roomFull$.getValue()) {
              this.currentState.next(Engagement.STATE_ENGAGED);
            } else {
              this.currentState.next(Engagement.STATE_TRANSFER_SELECTING);
            }

            break;
          default:
            throw new Error('Invalid state transition');
        }
      }
        break;
      default:
        throw new Error('Invalid state transition');
    }
  }

  public customerJoinRequest($event: CustomerInvite) {

    let obs: Observable<boolean>;
    switch ($event.type) {
      case CustomerInviteTypes.EMAIL:
        obs = this.inviteService.sendEmail($event.to, $event.content, $event.subject);
        break;
      case CustomerInviteTypes.SMS:
        obs = this.inviteService.sendSMS($event.to, $event.content);
        break;
    }

    if (obs) {
      obs.subscribe((sent) => {
          this.logging.debug(`sent invite: ${sent}`);
          if (sent) {
            this.alertService.addAlert(`Invitation sent`, AlertType.Success);
          } else {
            this.alertService.addAlert(`Failed to send invite`, AlertType.Danger);
          }

        },
        (err) => {
          this.alertService.addAlert(`Failed to send invite`, AlertType.Danger);
          this.logging.error("Failed to send Invite", err);
        });
    }

    this.currentState.next(Engagement.STATE_ENGAGED);

  }

  // todo: make this use an type rather than just details
  public sendInviteRequest(details: any) {
    this.logging.debug('State transition from: ' + this.currentState.value.type);
    const state = this.currentState.value;

    this.transferChatMessages = [];

    switch (state.type) {
      case EngagementState.Transfer: {
        switch (state.inviteState) {
          case CallInviteState.InviteSelecting:
            // do the outbound thingy
            this.sendTransferOutRequest(details.username, details.message);

            const newWaitingState: TransferStateWaiting = {
              type: EngagementState.Transfer,
              inviteState: CallInviteState.InviteWaiting,
              operatorUsername: details.username,
              operatorFullname: details.fullname,
            };

            this.currentState.next(newWaitingState);
            break;
          case CallInviteState.InviteRejected:
            // ignore, may be from an old transfer request
            break;
          case CallInviteState.InviteWaiting:
          default:
            throw new Error('Invalid state transition');
        }
      }
        break;

      case EngagementState.Join: {
        switch (state.inviteState) {
          case CallInviteState.InviteSelecting:
            // do the outbound thingy
            this.sendJoinRequest(details.username, details.message);

            const newWaitingState: JoinStateWaiting = {
              type: EngagementState.Join,
              inviteState: CallInviteState.InviteWaiting,
              operatorUsername: details.username,
              operatorFullname: details.fullname,
            };

            this.currentState.next(newWaitingState);
            break;
          case CallInviteState.InviteRejected:
            // ignore, may be from an old join request
            break;
          case CallInviteState.InviteWaiting:
          default:
            throw new Error('Invalid state transition');
        }
      }
        break;

      case EngagementState.Engaged:
      case EngagementState.Post:
      default:
        throw new Error('Invalid state transition');
    }
  }

  private sendJoinRequest(selectedOp: string, invitationReason: string) {
    this.engagementHub.sendJoinRequest(this.options.sitename, selectedOp, invitationReason)
      .catch(err => this.logging.warn(`Unable to send sendJoinRequest command in engagement ${this.engagementId}`, err));
  }

  private abortJoinRequest(operatorUsername: string) {
    this.engagementHub.abortJoinRequest(this.options.sitename, operatorUsername)
      .catch(err => this.logging.warn(`Unable to send abortJoinRequest command in engagement ${this.engagementId}`, err));
  }

  private joinAccepted() {
    this.currentState.next(Engagement.STATE_JOIN_ACCEPTED);
    this.currentState.next(Engagement.STATE_ENGAGED);
  }

  private joinRejected(operatorUsername: string, reason: string) {
    this.logging.debug('State transition from: ' + this.currentState.value.type);

    const state = this.currentState.value;

    switch (state.type) {
      case EngagementState.Join: {
        switch (state.inviteState) {
          case CallInviteState.InviteWaiting:
            const newRejectionState: JoinStateRejected = {
              type: EngagementState.Join,
              inviteState: CallInviteState.InviteRejected,
              operatorFullname: state.operatorFullname,
              operatorUsername,
              reason,
            };
            this.currentState.next(newRejectionState);
            break;
          case CallInviteState.InviteSelecting:
          default:
            this.logging.debug('Received join rejected too late, ignoring');
        }
      }
        break;
      case EngagementState.Engaged:
      case EngagementState.Post:
      default:
        this.logging.debug('Received join rejected too late, ignoring');
    }
  }

  private sendTransferOutRequest(selectedOp: string, transferReason: string) {
    const customerMessage = this.currentPage.getValue();

    this.engagementHub.notifyCustomerOfTransferOut(btoa(customerMessage));
    this.engagementHub.sendTransferOutRequest(this.options.sitename, selectedOp, transferReason);
  }

  private transferAccepted() {
    this.currentState.next(Engagement.STATE_TRANSFER_ACCEPTED);
    this.engagementEnds();
  }

  private transferRejected(operatorUsername: string, reason: string) {
    const state = this.currentState.value;

    switch (state.type) {
      case EngagementState.Transfer: {
        switch (state.inviteState) {
          case CallInviteState.InviteWaiting:
            const newRejectionState: TransferStateRejected = {
              type: EngagementState.Transfer,
              inviteState: CallInviteState.InviteRejected,
              operatorFullname: state.operatorFullname,
              operatorUsername,
              reason,
            };
            this.currentState.next(newRejectionState);
            break;
          case CallInviteState.InviteSelecting:
          default:
            this.logging.debug('Received transfer rejected too late, ignoring');
        }
      }
        break;
      case EngagementState.Engaged:
      case EngagementState.Post:
      default:
        this.logging.debug('Received transfer rejected too late, ignoring');
    }
  }

  public startDomSync(on: boolean) {
    this.domSyncOn$.next(on);
    this.engagementHub.setRoomProperty("SyncDom", on.toString())
      .catch(err => this.logging.warn(`Unable to send setRoomProperty command in engagement ${this.engagementId}`, err));
  }

  public setDomSyncOn(on: boolean) {
    if (on) {
      this.engagementHub.startDomSync()
        .catch(err => this.logging.warn(`Unable to send startDomSync command in engagement ${this.engagementId}`, err));
    } else {
      this.isDomSync$.next(false);
      this.domSyncAccepted$.next(false);
      this.domSyncOn$.next(false);

      this.engagementHub.stopDomSync()
        .catch(err => this.logging.warn(`Unable to send stopDomSync command in engagement ${this.engagementId}`, err));
    }
  }

  changePresenter(agentUsername: string) {
    const propertyName = 'PresentingAgent';
    return from(this.engagementHub.setRoomProperty(propertyName, agentUsername));
  }

  changePrimary(agentUsername: string): Observable<void> {
    const propertyName = 'PrimaryAgent';
    return from(this.engagementHub.setRoomProperty(propertyName, agentUsername));
  }

  kickAgent(agentUsername: string) {
    this.engagementHub.kickAgent(agentUsername)
      .catch(err => this.logging.warn(`Unable to send kickAgent command in engagement ${this.engagementId}`, err));
  }

  onKicked() {
    this.currentState.next(Engagement.STATE_ENGAGED);
    this.engagementEnds();
  }

  crmStateUpdated(data: VisitorCRMData) {
    // Only update the crm data if they aren't the primary agent.
    if (!this.isPrimaryAgent()) {
      const newData = JSON.parse(data.Data);
      this.visitor.setCrmData(this.crmService.createCustomerDataStructure(newData), this.crmService);
    }
  }

  onChatSuggestion(message: string) {
    if (this.agentAssistBotAutosendMessage.value) {
      this.sendChatMessage(message);
    } else {
      this.agentText = message;
    }
  }

  public lockRoom() {
    this.engagementHub.lockRoom()
      .catch(err => this.logging.warn(`Unable to send lockRoom command in engagement ${this.engagementId}`, err));
  }

  public unlockRoom() {
    this.engagementHub.unlockRoom()
      .catch(err => this.logging.warn(`Unable to send unlockRoom command in engagement ${this.engagementId}`, err));
  }

  public setCrmData(data: CrmStructure) {
    this.crmService.setCrmData(data, this.visitor.userGuid).subscribe(res => {
      if (res) {
        this.visitor.setCrmData(data, this.crmService);
        if (this.currentState.value.type !== EngagementState.Post && this.currentState.value.type !== EngagementState.Ended) {
          this.saveCrmData(this.visitor.userGuid, this.crmService.extractCustomerData(data));
        }
      }
    });
  }

  public getCustomerName(crmData: CrmData): string {
    const firstName = this.crmService.getCrmFieldValue(crmData, 'Customer Information', 'Firstname');
    const lastName = this.crmService.getCrmFieldValue(crmData, 'Customer Information', 'Lastname');
    return (firstName === undefined ? '' : firstName) + ' ' + (lastName === undefined ? '' : lastName);
  }

  public saveCrmData(userGuid: string, crmData: CrmData) {
    const fullName = this.getCustomerName(crmData);

    const visitorCrmData = new VisitorCRMData();
    visitorCrmData.Data = JSON.stringify(crmData);

    this.engagementHub.saveCrmData(userGuid, visitorCrmData)
      .catch(err => this.logging.warn(`Unable to send saveCrmData command in engagement ${this.engagementId}`, err));
    this.updateCustomerName(userGuid, fullName);
  }

  public updateAllVisitorData() {
    const visitorObservables = Array.from(this._roomVisitors.entries()).map(([userguid, visitor]) =>
      this.loadAndProcessVisitorData(userguid, visitor)
    );

    forkJoin(visitorObservables).subscribe(results => {
      this.handleVisitorDataUpdates(results);
    });
  }

  private loadAndProcessVisitorData(userguid: string, visitor: EngagementVisitor) {
    return this.crmService.loadCustomerData(userguid).pipe(
      take(1),
      map(data => this.processVisitorData(userguid, visitor, data)),
      catchError(error => {
        console.error(`Failed to load customer data for userguid ${userguid}`, error);
        return of(null);
      })
    );
  }

  private processVisitorData(userguid: string, visitor: EngagementVisitor, crmStructure: CrmStructure) {
    const crmData = this.crmService.extractCustomerData(crmStructure);
    const customerName = this.getCustomerName(crmData);
    return { userguid, visitor, customerName, crmData };
  }

  private handleVisitorDataUpdates(results: Array<{ userguid: string, visitor: EngagementVisitor, customerName: string, crmData: CrmData } | null>) {
    results
      .filter(result => result !== null)
      .forEach(({ userguid, visitor, customerName, crmData }) => {
        if (visitor) {
          visitor.customerName$?.next(customerName);
          visitor.crmData$?.next(crmData);
          this.updateCustomerName(userguid, customerName);
        }
      });
  }

  private updateCustomerName(userGuid: string, fullName: string) {
    this.engagementHub.updateCustomerName(userGuid, fullName)
      .catch(err => this.logging.warn(`Unable to send updateCustomerName command in engagement ${this.engagementId}`, err));
  }

  public switchCustomer(currentUserId: string, originalUserId: string) {
    this.engagementHub.switchCustomer(currentUserId, originalUserId)
      .catch(err => this.logging.warn(`Unable to send switchCustomer command in engagement ${this.engagementId}`, err));
  }

  private onAppViewStartRequest() {
    this.toggleAppView(true);
  }

  public toggleAppView(enable: boolean) {
    if (enable) {
      this.engagementHub.startAppView()
        .catch(err => this.logging.warn(`Unable to send startAppView command in engagement ${this.engagementId}`, err));
      this.isAppView.next(true);
    } else {
      this.engagementHub.stopAppView()
        .catch(err => this.logging.warn(`Unable to send stopAppView command in engagement ${this.engagementId}`, err));
      this.isAppView.next(false);
    }
  }

  // TODO: Move to utils
  private static isHTML(str: string): boolean {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  private onSupervisorJoined() {
    this.logging.debug('Joined engagement as a supervisor');
  }

  private onRejoinExistingEngagement() {
    this.logging.debug(`Rejoined existing engagement`);
  }

  public toggleHelpRequest() {
    if (this.requestingHelp$.value) {
      this.engagementHub.cancelHelpRequest()
        .catch(err => this.logging.warn(`Unable to send cancelHelpRequest command in engagement ${this.engagementId}`, err));
    } else {
      this.engagementHub.requestHelp()
        .catch(err => this.logging.warn(`Unable to send requestHelp command in engagement ${this.engagementId}`, err));
      this.enablePrivateChat();
    }

    this.requestingHelp$.next(!this.requestingHelp$.value);
  }

  public onAgentAssistAnswered() {
    this.requestingHelp$.next(false);
  }

  public chooseFeed(feedType: FeedType, feedId: number) {
    this.engagementHub.chooseFeed(this.authService.currentAgent.value.username, feedType, feedId, this.authService.currentAgent.value.servers.WEBRTCGATEWAYURL);
  }

  public agentTextChange(agentText: string) {
    this.agentText = agentText;
    if (this.agentText != null && this.agentText.length > 0) {
      this.opTyping();
    } else {
      this.opDeletedTyping();
    }
  }

  // any time the communication mode changes, check that the agent is capable of supporting that mode
  // if they cannot, then downgrade to a mode they can support
  public enforceAgentPermissions() {
    this.communicationMode
      .pipe(distinctUntilChanged())
      .subscribe((commMode) => {
        const hasAudio = this.featureService.has(Features.AUDIO_MODE);
        const hasNoVideo = this.featureService.has(Features.NO_VIDEO);

        if (commMode === CommunicationMode.TWOWAYAUDIO_NOVIDEO) {
          if (!hasAudio) {
            this.switchToTextMode();
          }
        } else if (commMode === CommunicationMode.TWOWAYAUDIO_VIDEO) {
          if (hasNoVideo && !hasAudio) {
            this.switchToTextMode();
          } else if (hasNoVideo && hasAudio) {
            this.switchToAudioMode();
          }
        }
      });
  }

  public removeBrowser() {
    this.engagementHub.removeBrowser()
      .catch(err => this.logging.warn(`Unable to send removeBrowser command in engagement ${this.engagementId}`, err));
  }

  public changeCameraDirection(newDirection: CameraDirection) {
    this.engagementHub.changeCameraDirection(true, newDirection)
      .catch(err => this.logging.warn(`Unable to send changeCameraDirection command in engagement ${this.engagementId}`, err));
  }

  private onVisitorNavigation(navigating: boolean) {
    if (navigating) {
      // The visitor has started navigating
    } else {
      // The visitor has finished navigating
      if (this.isDomSync$.value) {
        this.domSyncCommand$.next('restart');
        this.setDomSyncOn(true);
      }
    }
  }

  public agentVideoUnDocked(unDocked: boolean) {
    this.engagementHub.agentVideoUnDocked(unDocked);
  }

  public changeEngagementMode(newMode: EngagementMode) {
    if (this.engagementMode.getValue() != newMode) {
      this.engagementHub.changeEngagementMode(newMode)
        .catch(err => {
          this.logging.error(`Unable to switch engagement mode to ${newMode}`, err);
        });
    }
  }

  public transferChatMessages: TextMessage[] = [];

  onTransferChatMessage(textMessage: TextMessage) {
    this.transferChatMessages.push(textMessage)
    this.transferChatMessages = [...this.transferChatMessages];
  }

  private engagementTypeMessage(engagementType: string) {
    if (engagementType == null) {
      engagementType = '';
    }

    switch (engagementType.toLowerCase()) {
      default:
        return `${engagementType} Engagement`;
      case 'veecall':
        return 'VeeStudio Engagement';
      case 'chat':
        return 'VeeChat Engagement';
    }
  }

  loadMoreHistory() {
    if (this.chatHistory.loading) {
      this.logging.debug(`Already loading a transcript.`);
    } else if (this.chatHistory.done) {
      this.logging.debug(`Finished loading transcript.`);
    } else {
      this.chatHistory.loadMore().then(items => {
        this.messages.addHistory(this.chatHistory.history);
        this.logging.debug(`Loaded ${items.length} messages.`);
      });
    }
  }

  getRoomVisitor(userguid: string) {
    return this._roomVisitors.get(userguid);
  }

  confirmOtp(userguid: string, to: string) {
    return this.engagementHub.confirmOtp(userguid, to);
  }

  sendOtp(userguid: string, to: string) {
    return this.engagementHub.sendOtp(userguid, to);
  }
}
