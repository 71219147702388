<form [formGroup]="appointmentFormGroup" class="form-container mt-4">
  <div class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_APPOINTMENT_TYPE" | Translate : 'Appointment Type' }}</label>
      <p-dropdown class="dropdown" formControlName="appointmentType"
                  optionLabel="name"
                  [options]="appointmentTypes"
                  [filter]="true"
                  [placeholder]="'APPOINTMENT_LABEL_APPOINTMENT_SELECT_TYPE' | Translate : 'Select Appointment Type'"
                  [resetFilterOnHide]="true"
                  [showClear]="false"></p-dropdown>
    </div>
  </div>

  <div *ngIf="(appointmentLocations$ | async)?.length > 0" class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_APPOINTMENT_LOCATION" | Translate : 'Location' }}</label>
      <p-dropdown [filter]="true" [options]="(appointmentLocations$ | async)"
                  [placeholder]="'APPOINTMENT_LABEL_APPOINTMENT_SELECT_LOCATION' | Translate : 'Select Location'"
                  [resetFilterOnHide]="true"
                  [showClear]="true"
                  class="dropdown"
                  formControlName="locationId"
                  optionLabel="name" optionValue="id"></p-dropdown>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_START_DATE" | Translate : 'Appointment Date' }}</label>
      <input class="form-control flex-1" formControlName="startDate" pInputText type="date">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-12">
      <label>{{ "APPOINTMENT_LABEL_TIMESLOT" | Translate : 'Appointment Time' }} ({{ timeZone$ | async }}
        - {{ Timezones.getIanaTimeZone((timeZone$ | async)) }})</label>
      <p-dropdown [options]="(availabilitySlots$ | async)"
                  [placeholder]="'APPOINTMENT_LABEL_SELECT_TIMESLOT' | Translate : 'Select Appointment Time'"
                  [showClear]="false"
                  class="dropdown"
                  formControlName="timeslot"
                  optionLabel="label">
      </p-dropdown>
      <small *ngIf="appointmentTypeId !== -1 && (availabilitySlots$ | async)?.length === 0"
             class="text-danger">{{ "APPOINTMENT_LABEL_TIMESLOT_NOT_AVAILABLE" | Translate : 'No Timeslots Available' }}</small>
    </div>
  </div>

  <p-divider *ngIf="formFields?.length > 0" layout="horizontal"></p-divider>

  <div [formGroup]="dynamicFormGroup" class="form-container">
    <ng-container *ngFor="let field of formFields">
      <app-crm-field-error [ngStyle]="{'display' : isVisible(field.name) ? 'block' : 'none' }"
                           [canEditCrm]="true"
                           [field]="field"
                           [canShowRequired]="true"
                           [ngSwitch]="StringUtils.capitalise(field.type)">

        <app-crm-field-email *ngSwitchCase="CrmField.TYPE_EMAIL"
                             [field]="field" [formControlName]="field.name"
                             (saveField)="saveCrmData()">
        </app-crm-field-email>

        <app-crm-field-phone-number (saveField)="saveCrmData()"
                                    *ngSwitchCase="CrmField.TYPE_PHONE" [field]="field"
                                    [formControlName]="field.name">
        </app-crm-field-phone-number>

        <app-crm-field-note *ngSwitchCase="CrmField.TYPE_NOTE"
                            [field]="field" [formControlName]="field.name"
                            (saveField)="saveCrmData()">
        </app-crm-field-note>

        <app-crm-field-number *ngSwitchCase="CrmField.TYPE_NUMBER"
                              [field]="field" [formControlName]="field.name"
                              (saveField)="saveCrmData()">
        </app-crm-field-number>

        <app-crm-field-datetime *ngSwitchCase="CrmField.TYPE_DATETIME"
                                [field]="field" [formControlName]="field.name"
                                (saveField)="saveCrmData()">
        </app-crm-field-datetime>

        <app-crm-field-choice *ngSwitchCase="CrmField.TYPE_CHOICE"
                              [field]="field" [selectControl]="dynamicFormGroup.get(field.name)"
                              (saveField)="saveCrmData()">
        </app-crm-field-choice>

        <app-crm-field-checkbox *ngSwitchCase="CrmField.TYPE_CHECKBOX"
                                [field]="field" [formControlName]="field.name"
                                (saveField)="saveCrmData()">
        </app-crm-field-checkbox>

        <app-crm-field-text (saveField)="saveCrmData()" *ngSwitchDefault
                            [field]="field" [formControlName]="field.name">
        </app-crm-field-text>

      </app-crm-field-error>
    </ng-container>
  </div>

  <div class="d-flex flex-row align-items-center justify-content-end gap-1">
    <p-button [disabled]="!appointmentFormGroup.dirty && !dynamicFormGroup.dirty" type="button"
              [label]="'APPOINTMENT_LABEL_CLEAR' | Translate: 'Clear'" severity="secondary"
              [text]="true" (onClick)="reset()">
    </p-button>
    <p-button (onClick)="createAppointment()" [disabled]="!appointmentFormGroup.valid || !dynamicFormGroup.valid"
              [loading]="submittingForm"
              [pTooltip]="'APPOINTMENT_LABEL_SAVE' | Translate: 'Save'" severity="success">
      <fa-icon [icon]="faSave"></fa-icon>
    </p-button>
  </div>
</form>
